import React, { Component } from "react";

import axios from "axios";

import "./_M-signupForm.scss";

import { Button, FormControl, FormLabel } from "react-bootstrap";
import URL from "url-parse";
import {
  industry_list,
  stage_list,
  business_type,
  add_company_type_list,
} from "../../../boilerplate/list.json";

import waterfall from "async/waterfall";
import { AppContext } from "../../../App";

import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import Geocode from "react-geocode";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

let userInfo;

class SignupForm_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;

  render() {
    return <SignupForm {...this.props} {...this.context} />;
  }
}

class SignupForm extends Component {
  state = {
    url: new URL(window.location.href, true),
    check_email_message: "",

    userFormDisplay: Boolean,
    companyFormDisplay: Boolean,

    //****************/Signup User/***********************//
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    referral_partners: [],
    referred_by: null,
    confirmed_privacy_poicy_terms: false,

    //****************/singup Company/***********************//
    company_name: "",
    company_type: "",
    street_number: "",
    opportunity_id: "",
    short_bio: "",
    website: "",
    street_name: "",
    city: "",
    province: "Ontario",
    country: "Canada",
    industry: [],
    additional_Info: [],
    business_overview: "",
    address: "",
    stage: "",
    business_model: "",
    formIsValids: false,
    errors: {},
    showButtonLoader: false,
    submitted: false,
    old_address: "",
    incubator_accelerator_history: "",
    linkedIn: "",
    contact_phone: "",
    raising_funds: "",
    fintech_sub_vertical: "",
    finance_mtl_q1: "",
    finance_mtl_q2: "",
    finance_mtl_q3: "",
    finance_mtl_q4: "",
    inviteFounder: false,
    prospective_aff_email: "",
  };

  inviteFounderSwitch = () => {
    if (this.state.inviteFounder) {
      this.setState({ inviteFounder: false });
    } else {
      this.setState({ inviteFounder: true });
    }
    console.log("this.state.inviteFounder = " + this.state.inviteFounder);
  };

  componentDidMount() {
    axios.get("fetch_referral_partners").then((response) => {
      this.setState({
        opportunity_id: this.state.url.query.opportunity_id,
        referral_partners: response.data.referral_partners,
      });
    });
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        if (name === "address") {
          this.getLocation();
        }
      },
    );
  };

  handleChangeMultiple = (e) => {
    const name = e.target.name;
    let selected_values = [];
    Array.from(e.target.selectedOptions).forEach(function (item) {
      selected_values.push(item.value);
    });
    this.setState({
      [name]: selected_values,
    });
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };
  getReferrer = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i]._id === value) {
        return `${array[i].first_name} ${array[i].last_name}`;
      }
    }
  };
  validateResult = (data, callback) => {
    if (data.status === "success" || data.status === "partial") {
      callback(data, this);
    } else if (data.status === "redirect") {
      this.props.history.push(data.redirect_to);
    } else if (data.status === "error") {
    }
  };

  handleCompanyValidation = async (event) => {
    const tearshetTable = {
      row: [
        { name: "" },
        { name: "Q1" },
        { name: "Q2" },
        { name: "Q3" },
        { name: "Q4" },
        { name: "Q1" },
        { name: "Q2E" },
        { name: "Q3E" },
        { name: "Q4E" },
        { name: "2025E" },
        { name: "2026E" },
      ],
      Rev: [
        { name: "Rev" },
        { name: "$0k" },
        { name: "$0k" },
        { name: "$1k" },
        { name: "$3k" },
        { name: "$10k" },
        { name: "$25k" },
        { name: "$50k" },
        { name: "$100k" },
        { name: "$1M" },
        { name: "$2.5M" },
      ],
      Burn: [
        { name: "Burn" },
        { name: "$35k" },
        { name: "$35k" },
        { name: "$51k" },
        { name: "$55k" },
        { name: "$75k" },
        { name: "$90k" },
        { name: "$110k" },
        { name: "$120k" },
        { name: "$500K" },
        { name: "$1M" },
      ],
      Net: [
        { name: "Net" },
        { name: "($35k)" },
        { name: "($35k)" },
        { name: "($50k)" },
        { name: "($52k)" },
        { name: "($65k)" },
        { name: "($65k)" },
        { name: "($60k)" },
        { name: "($20k)" },
        { name: "$500K" },
        { name: "$1.5M" },
      ],
      Inv: [
        { name: "Inv" },
        { name: "$15k" },
        { name: "$15k" },
        { name: "$100k" },
        { name: "$90k" },
        { name: "$60k" },
        { name: "$60k" },
        { name: "$60k" },
        { name: "$0k" },
        { name: "$0K" },
        { name: "$0M" },
      ],
      Cash: [
        { name: "Cash" },
        { name: "($20k)" },
        { name: "($40k)" },
        { name: "$10k" },
        { name: "$48k" },
        { name: "$43k" },
        { name: "$38k" },
        { name: "$38k" },
        { name: "$18k" },
        { name: "$518K" },
        { name: "$1.53M" },
      ],
    };

    const CaptableData = {
      Layer: [66.6],
      Enta: [22.5],
      ESOP: [9.9],
    };

    event.preventDefault();
    this.setState({
      showButtonLoader: true,
    });
    let errors = {};
    let formIsValid = true;

    if (this.state.company_name.length === 0) {
      formIsValid = false;
      errors["company_name"] = " Cannot be empty";
    }
    if (this.state.company_type.length === 0) {
      formIsValid = false;
      errors["company_type"] = " Cannot be empty";
    }
    if (this.state.industry.length === 0) {
      formIsValid = false;
      errors["industry"] = " Cannot be empty";
    }
    if (this.state.stage === 1) {
      formIsValid = false;
      errors["company_stage"] = " Cannot be empty";
    }
    if (this.state.address.length === 0) {
      formIsValid = false;
      errors["location"] = " Cannot be empty";
    }

    this.setState({ errors: errors });

    if (formIsValid) {
      try {
        const result = await axios.post("/create_company_pitchbox", {
          business_CEO: "firstname lastname",
          business_Product: "firstname lastname",
          business_Finance: "firstname lastname",
          business_Marketing: "firstname lastname",
          business_Sales: "firstname lastname",
          Captable_overview: CaptableData,
          discount: "0%",
          post_Cap: "0M",
          Convertible: "0k",
          Ticket_Size: "$0k - $0k",
          hard_Committed: "0k",
          soft_Committed: "0k",
          soft_Committed_founder: "firstname lastname",

          user_id: this.props.user._id,
          opportunity_id: this.state.opportunity_id,
          company_name: this.state.company_name,
          company_type: this.state.company_type,
          street_number: this.state.street_number,
          short_bio: this.state.short_bio,
          company_website: this.state.website,
          street_name: this.state.street_number,
          city: this.state.city,
          province: this.state.province,
          country: this.state.country,
          industry: this.state.industry,
          additional_Info: tearshetTable,
          business_overview:
            "Improving the emotional connection between the consumer and retail brands. Using live stream, video, fit measurement and other features, Unity increases promotion and reduces returns on retail purchases.",
          stage: this.state.stage,
          business_model: this.state.business_model,
          address: this.state.address,
          formatted_address: this.state.formatted_address,
          lat: this.state.lat,
          long: this.state.long,
          prospective_aff_email: this.state.prospective_aff_email,
          stealth: true,
        });

        if (result.data.status === "error") {
          let errors = {};
          errors["prospective_aff_email"] = " User already exists";
          this.setState({ errors: errors });
        } else {
          this.setState({
            submitted: true,
          });
          const response = await axios.post("/create_opportunity_application", {
            applicant_company: result.data.result._id,
            pertains_to_opportunity: this.state.opportunity_id,
          });
          console.log("submitted a company");
          console.log(response);
          document.location.reload();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.setState({
          showButtonLoader: false,
        });
      }
    } else {
      this.setState({
        showButtonLoader: false,
      });
    }
  };

  getUserInfo = (userInfo) => {
    let userData = userInfo;
    return { userData: userData };
  };
  handleInputSubmit = (e) => {
    e.preventDefault();
  };
  getLocation = () => {
    Geocode.setApiKey("AIzaSyAWUPaGMMe9qXj5b5clWHjBWuYhXaCrDTI");
    // Enable or disable logs. Its optional.
    Geocode.enableDebug();

    // Get latidude & longitude from address.
    if (this.state.old_address !== this.state.address) {
      this.setState(
        {
          old_address: this.state.address,
        },
        () => {
          Geocode.fromAddress(this.state.address).then(
            (response) => {
              const address_array =
                response.results[0].formatted_address.split(", ");
              const { lat, lng } = response.results[0].geometry.location;
              if (address_array.length === 4) {
                this.setState({
                  location_processed: 1,
                  lat: lat,
                  long: lng,
                  city: address_array[1],
                  province: address_array[2],
                  country: address_array[3],
                  formatted_address: response.results[0].formatted_address,
                });
              } else if (address_array.length === 3) {
                this.setState({
                  location_processed: 1,
                  lat: lat,
                  long: lng,
                  city: address_array[0],
                  province: address_array[1],
                  country: address_array[2],
                  formatted_address: response.results[0].formatted_address,
                });
              }
            },
            (error) => {
              console.error(error);
            },
          );
        },
      );
    }
  };

  handleCheck = (e) => {
    let name = e.target.name;
    this.setState({
      [name]: !this.state[name],
    });
  };
  render() {
    const { classes } = this.props;
    const { submitted } = this.state;
    let companySignUp;

    if (this.state.companyFormDisplay) {
      companySignUp = (
        <React.Fragment>
          {submitted && (
            <div>
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  marginTop: "10px",
                }}
              >
                <i
                  className="fa"
                  style={{ fontSize: "60px", color: "#6697ed" }}
                >
                  <span className="fas fa-tools"></span>
                </i>
              </div>
              <h3>Startup Added. </h3>
              <h3> </h3>
              <br />
            </div>
          )}

          {!submitted && (
            <>
              <div className="__sf_company_registration">
                <form id="company-registration">
                  <div className="form-gorup">
                    <FormLabel className="control-label">
                      Company Name*
                    </FormLabel>
                    <span className="text-danger">
                      {this.state.errors["company_name"]}
                    </span>
                    <FormControl
                      type="text"
                      value={this.state.company_name}
                      placeholder="Company Name"
                      name="company_name"
                      onChange={this.handleChange}
                      className="form-control mb-3"
                    />
                  </div>
                  <div className="form-gorup">
                    <FormLabel className="control-label">
                      Company Website
                    </FormLabel>
                    <span className="text-danger">
                      {this.state.errors["website"]}
                    </span>
                    <FormControl
                      type="text"
                      value={this.state.website}
                      placeholder="https://mycompany.com"
                      name="website"
                      onChange={this.handleChange}
                      className="form-control mb-3"
                    />
                  </div>
                  <div className="form-gorup">
                    <FormLabel className="control-label">
                      Company Type*
                    </FormLabel>
                    <span className="text-danger">
                      {this.state.errors["company_type"]}
                    </span>
                    <Select
                      value={this.state.company_type}
                      onChange={this.handleChange}
                      input={<Input id="select-multiple-chip" />}
                      name="company_type"
                      style={{ display: "inherit" }}
                      renderValue={(selected) => (
                        <div>
                          <MenuItem
                            key={this.state.company_type}
                            value={this.state.company_type}
                          >
                            {this.state.company_type}
                          </MenuItem>
                        </div>
                      )}
                      MenuProps={{
                        autoFocus: false,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null, // Use this to prevent the dropdown from moving
                        style: {
                          maxHeight: 300,
                        },
                      }}
                    >
                      {add_company_type_list.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <br />
                  <br />

                  <FormLabel className="control-label">
                    Office Address or Location (City, Country)*
                  </FormLabel>
                  <span className="text-danger">
                    {this.state.errors["location"]}
                  </span>
                  <input
                    type="text"
                    style={{ width: "100%" }}
                    name="address"
                    value={this.state.address}
                    onChange={this.handleChange}
                  />
                  <div className="form-gorup"></div>
                  <div className="form-gorup">
                    {(this.state.company_type === "Startup" ||
                      this.state.company_type === "Tech Startup" ||
                      this.state.company_type.length === 0) && (
                      <InputLabel
                        htmlFor="select-multiple-chip"
                        className="control-label mrginTop"
                        style={{
                          fontSize: "12px",
                          marginBottom: "-1px",
                          marginTop: "37px",
                        }}
                      >
                        Industry Disrupted*
                      </InputLabel>
                    )}
                    {this.state.company_type === "Service Provider" && (
                      <InputLabel
                        htmlFor="select-multiple-chip"
                        className="control-label mrginTop"
                        style={{
                          fontSize: "12px",
                          marginBottom: "-1px",
                          marginTop: "37px",
                        }}
                      >
                        Industries of the companies that you work with?
                      </InputLabel>
                    )}
                    {this.state.company_type !== "Service Provider" &&
                      this.state.company_type !== "Startup" &&
                      this.state.company_type !== "Tech Startup" &&
                      this.state.company_type.length > 0 && (
                        <InputLabel
                          htmlFor="select-multiple-chip"
                          className="control-label mrginTop"
                          style={{
                            fontSize: "12px",
                            marginBottom: "-1px",
                            marginTop: "37px",
                          }}
                        >
                          Industries of the companies that you are looking for?
                        </InputLabel>
                      )}
                    <span className="text-danger">
                      {this.state.errors["industry"]}
                    </span>
                    <Select
                      multiple
                      value={this.state.industry}
                      onChange={this.handleChange}
                      input={<Input id="select-multiple-chip" />}
                      name="industry"
                      style={{ width: "100%" }}
                      renderValue={(selected) => (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "2px",
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </div>
                      )}
                      MenuProps={{
                        autoFocus: false,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null, // Use this to prevent the dropdown from moving
                        style: {
                          maxHeight: 300,
                        },
                      }}
                    >
                      {industry_list.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={this.state.industry.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <div className="form-gorup">
                    {(this.state.company_type === "Startup" ||
                      this.state.company_type === "Tech Startup" ||
                      this.state.company_type.length === 0) && (
                      <InputLabel
                        htmlFor="select-multiple-chip"
                        className="control-label mrginTop"
                        style={{
                          fontSize: "12px",
                          marginBottom: "-1px",
                          marginTop: "37px",
                        }}
                      >
                        Business Model
                      </InputLabel>
                    )}
                    {this.state.company_type === "Service Provider" && (
                      <InputLabel
                        htmlFor="select-multiple-chip"
                        className="control-label mrginTop"
                        style={{
                          fontSize: "12px",
                          marginBottom: "-1px",
                          marginTop: "37px",
                        }}
                      >
                        Business Models of the companies that you work with?
                      </InputLabel>
                    )}
                    {this.state.company_type !== "Service Provider" &&
                      this.state.company_type !== "Startup" &&
                      this.state.company_type !== "Tech Startup" &&
                      this.state.company_type.length > 0 && (
                        <InputLabel
                          htmlFor="select-multiple-chip"
                          className="control-label mrginTop"
                          style={{
                            fontSize: "12px",
                            marginBottom: "-1px",
                            marginTop: "37px",
                          }}
                        >
                          Business Models of the companies that you are looking
                          for?
                        </InputLabel>
                      )}
                    {/* <br/> */}
                    <Select
                      value={this.state.business_model}
                      onChange={this.handleChange}
                      input={<Input id="select-multiple-chip" />}
                      name="business_model"
                      style={{ width: "100%", height: "auto" }}
                      renderValue={(selected) => (
                        <div>
                          <MenuItem
                            className="UBaidDropDownMenu"
                            key={this.state.business_model}
                            value={this.state.business_model}
                          >
                            {this.state.business_model}
                          </MenuItem>
                        </div>
                      )}
                      MenuProps={{
                        autoFocus: false,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null, // Use this to prevent the dropdown from moving
                        style: {
                          maxHeight: 300,
                        },
                      }}
                    >
                      {business_type.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <div className="form-gorup">
                    {(this.state.company_type === "Startup" ||
                      this.state.company_type === "Tech Startup" ||
                      this.state.company_type.length === 0) && (
                      <InputLabel
                        htmlFor="select-multiple-chip"
                        className="control-label mrginTop"
                        style={{
                          fontSize: "12px",
                          marginBottom: "-1px",
                          marginTop: "37px",
                        }}
                      >
                        Company Stage
                      </InputLabel>
                    )}
                    {this.state.company_type === "Service Provider" && (
                      <InputLabel
                        htmlFor="select-multiple-chip"
                        className="control-label mrginTop"
                        style={{
                          fontSize: "12px",
                          marginBottom: "-1px",
                          marginTop: "37px",
                        }}
                      >
                        Stages of the companies that you like to work with?
                      </InputLabel>
                    )}
                    {this.state.company_type !== "Service Provider" &&
                      this.state.company_type !== "Startup" &&
                      this.state.company_type !== "Tech Startup" &&
                      this.state.company_type.length > 0 && (
                        <InputLabel
                          htmlFor="select-multiple-chip"
                          className="control-label mrginTop"
                          style={{
                            fontSize: "12px",
                            marginBottom: "-1px",
                            marginTop: "37px",
                          }}
                        >
                          Stages of the companies that you are looking for?
                        </InputLabel>
                      )}
                    <span className="text-danger">
                      {this.state.errors["company_stage"]}
                    </span>

                    <Select
                      value={this.state.stage}
                      onChange={this.handleChange}
                      input={<Input id="select-multiple-chip" />}
                      name="stage"
                      style={{ width: "100%", height: "auto" }}
                      renderValue={(selected) => (
                        <div>
                          <MenuItem
                            key={this.state.stage}
                            value={this.state.stage}
                            className="UBaidDropDownMenu"
                          >
                            {this.getStage(this.state.stage, stage_list)}
                          </MenuItem>
                        </div>
                      )}
                      MenuProps={{
                        autoFocus: false,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null, // Use this to prevent the dropdown from moving
                        style: {
                          maxHeight: 300,
                        },
                      }}
                    >
                      {stage_list.map((name) => (
                        <MenuItem key={name.text} value={name.value}>
                          {name.text}
                        </MenuItem>
                      ))}
                    </Select>
                    <div>&nbsp;</div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.inviteFounder}
                            onChange={this.inviteFounderSwitch}
                          />
                        }
                        color="secondary"
                        label="Invite deal rep (Founder/CEO/CFO) by email"
                      />
                    </FormGroup>
                    {this.state.inviteFounder ? (
                      <div className="form-gorup">
                        <FormLabel className="control-label">
                          Add Founder's Email
                        </FormLabel>
                        <TextField
                          type="email"
                          placeholder="example@gmail.com"
                          name="prospective_aff_email"
                          value={this.state.prospective_aff_email}
                          onChange={this.handleChange}
                          helperText={
                            this.state.errors["prospective_aff_email"]
                          }
                          error={this.state.errors["prospective_aff_email"]}
                          fullWidth
                        />
                        An email will be sent to the founder indicating your
                        interest in their round. It will ask them to submit
                        their data room and update their deal details in your
                        PitchBox.
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div>&nbsp;</div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      marginTop: "2px",
                    }}
                  >
                    {/* <button
                className="fuel"
                onClick={this.handleCompanyValidation}
                style={{
                  width: "30%",
                  color: "white",
                  background: "rgb(235, 25, 25)",
                  position: "relative",
                  border: "none",
                }}
              >
                Add Company
              </button> */}

                    <Button
                      type="submit"
                      onClick={this.handleCompanyValidation}
                      className="btn btn-primary btn-lg btn-block login-button my-1"
                      disabled={this.state.showButtonLoader}
                    >
                      {this.state.showButtonLoader ? "Submitting..." : "Submit"}
                    </Button>
                    {/* <button
                className="fuel"
                onClick={this.props.closeModal}
                style={{
                  width: "20%",
                  color: "white",
                  background: "rgb(33, 25, 58)",
                  position: "relative",
                  border: "none",
                }}
              >
                Close
              </button> */}
                  </div>
                </form>
              </div>
            </>
          )}
        </React.Fragment>
      );
    }

    const renderSwitch = () => {
      switch (this.props.isLoggedIn || this.props.companies_owned) {
        case this.props.isLoggedIn:
          return <>{companySignUp}</>;
      }
    };

    return (
      <div>
        <div className="signupForm__container">
          <div>{renderSwitch()}</div>
          {Object.keys(this.state.errors).length >= 1 && (
            <p style={{ color: "red", fontSize: "0.7rem" }}>
              {" "}
              Please fix the errors listed above{" "}
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default SignupForm_Container;
