/* (2019-06-10) - This page lists all the opportunities on the site, it also
contains components that allow you to query and find opportunities. It maps
through a list of the opportunities => passes them to a card. The sort and
filters are also used. The components can be found in /components. This
page contains the functions that put all of the pieces together.
*/
import React from "react";
import axios from "axios";
import "../main.css";
import { Helmet } from "react-helmet";
import StartupFuel from "../img/watermarksffinal.png";
import URL from "url-parse";
import default_pic from "../img/default_company_pic.png";
import default_background from "../img/bg.png";
import { AppContext } from "../App";
import Navigation from "./components/navbar";
import Footer from "./components/footer_side";
import {
  Sign_In,
  Register,
  Forgot_Password,
  Alert_Message_To_User,
} from "./components/popups";
import Opportunity_Card from "./components/opportunity_card";
import SortBy from "./components/sort_filter";
import Filters from "./components/filters";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../boilerplate/list.json";
import Grid from "@material-ui/core/Grid";
import update from "immutability-helper";
import SiteTitle from "./components/site_title.js";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import Loader from "./components/loader3";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import SideMenu from "./SideMenu";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import EventLive from "@material-ui/icons/EventAvailable";
import EventDead from "@material-ui/icons/EventBusy";
import "./opportunitiesUbaid.css";

class Opportunities_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return <Opportunities {...this.props} {...this.context} />;
  }
}

class Opportunities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      showSignInPopup: false,
      menuValue: 0,
      opportunity_list: [],
      opportunity_ids: [],
      showLoader: true,
      //filters: [{business_model: 'B2C'}, {country: 'Canada'}]
      query_search: "",
      old_count: 0,
      results: [],

      sort_old: "1",
      items: [],
      sortby: "",

      apply_filter: false,
      filters: {},
      filters_filtered: false,
    };
  }

  sortFunction = () => {
    if (this.state.sort_old !== this.state.sortby) {
      this.setState({
        sort_old: this.state.sortby,
      });

      function soryByLength(a, b) {
        return b.has_applicant_room - a.has_applicant_room;
      }

      function compareDeadline(a, b) {
        return new Date(b.end_date) - new Date(a.end_date);
      }
      function compareCreated(a, b) {
        return new Date(b.created_on) - new Date(a.created_on);
      }
      function compareNumbers(a, b) {
        return b.amount - a.amount;
      }
      function balphabetically(a, b) {
        if (a.opportunity_name.toLowerCase() > b.opportunity_name.toLowerCase())
          return -1;
        else if (
          a.opportunity_name.toLowerCase() < b.opportunity_name.toLowerCase()
        )
          return 1;
        return 0;
      }
      function alphabetically(a, b) {
        if (a.opportunity_name.toLowerCase() > b.opportunity_name.toLowerCase())
          return 1;
        else if (
          a.opportunity_name.toLowerCase() < b.opportunity_name.toLowerCase()
        )
          return -1;
        return 0;
      }
      if (this.state.sortby === "") {
        var x = this.state.opportunity_list.sort(soryByLength);
      }
      if (this.state.sortby === "latest") {
        var x = this.state.opportunity_list.sort(compareCreated);
      }
      if (this.state.sortby === "deadline") {
        var x = this.state.opportunity_list.sort(compareDeadline);
      }
      if (this.state.sortby === "price") {
        var x = this.state.opportunity_list.sort(compareNumbers);
      }
      if (this.state.sortby === "alphabetically") {
        var x = this.state.opportunity_list.sort(alphabetically);
      }
      if (this.state.sortby === "balphabetically") {
        var x = this.state.opportunity_list.sort(balphabetically);
      }
      this.setState(
        {
          opportunity_list: x,
        },
        function () {
          this.filterList(this.state.opportunity_list, (_this) => {
            _this.getInfo(
              _this.state.opportunity_list.filter((company) =>
                _this.state.opportunity_ids.includes(company._id),
              ),
            );
          });
        },
      );
    }
  };

  fetchAssets = (asset_type, state_component, callback) => {
    axios
      .get(`/fetch_${asset_type}`)
      .then((response) => {
        if (response.data.status === "success") {
          this.setState(
            {
              [state_component]: response.data.result,
            },
            function () {
              if (callback) {
                callback(this);
              }
            },
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  filterList = (list, callback) => {
    let checkArr = (heystack, arr) => {
      return arr.some(function (v) {
        return heystack.indexOf(v) >= 0;
      });
    };

    let filterz = [];

    for (let key in this.state.filters) {
      let display = list.filter((co) => {
        if (Array.isArray(co[key])) {
          return checkArr(this.state.filters[key], co[key]);
        } else {
          return this.state.filters[key].indexOf(co[key]) >= 0;
        }
      });
      filterz.push(...display.map((company) => company._id));
    }
    let counts = {};
    for (let o = 0; o < filterz.length; o++) {
      let string = filterz[o];
      counts[string] = counts[string] ? counts[string] + 1 : 1;
    }
    let results = [];
    for (let ki in counts) {
      if (counts[ki] === Object.keys(this.state.filters).length) {
        results.push(ki);
      }
    }
    this.setState(
      {
        opportunity_ids:
          Object.keys(this.state.filters).length > 0
            ? results
            : this.getCompanyInfo(this.state.opportunity_list, "_id"),
        apply_filter: true,
      },
      function () {
        if (callback) {
          callback(this);
        }
      },
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => this.sortFunction(),
    );
  };

  handleLogOut = () => {
    axios
      .get(`/logout`)
      .then((response) => {
        if (response.data.status === "success") {
          this.setState(
            {
              isLoggedIn: false,
            },
            function () {
              this.props.history.push(`/sign_in`);
            },
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCompanyInfo = (array, field) => {
    return array.map((co) => co[field]);
  };
  // shouldComponentUpdate(nxtProps, nxtState) {
  //   if (nxtProps.isLoggedIn !== this.props.isLoggedIn) {
  //     if (nxtProps.isLoggedIn === true) {
  //       this.setState({ filterPosition: 'ubaidStatic' })
  //     }
  //     else {
  //       this.setState({ filterPosition: 'ubaidFilterBox2' })
  //     }
  //   }
  //   return true
  // }
  componentDidMount() {
    axios
      .post("/fetch_multiple_directories_no_tag", {
        opportunity_type: [
          "Debt Financing",
          "Venture Capital Fund",
          "Angel Groups",
        ],
      })
      .then((funding) => {
        if (funding.data.status === "success") {
          this.setState(
            {
              opportunity_list: funding.data.result,
            },
            () => {
              this.setState(
                {
                  opportunity_ids: this.getCompanyInfo(
                    this.state.opportunity_list,
                    "_id",
                  ),
                  showLoader: false,
                },
                () => {
                  this.sortFunction();
                  this.setState({
                    active_comps: this.state.opportunity_list,
                  });
                },
              );
            },
          );
        }
      });

    // this.fetchAssets(`active_pitch_comps`, 'opportunity_list', function (_this) {
    //   _this.setState({
    //     opportunity_ids: _this.getCompanyInfo(_this.state.opportunity_list, '_id'),
    //     showLoader: false,
    //
    //   }, () => {
    //     _this.sortFunction()
    //     _this.setState({
    //       active_comps: _this.state.opportunity_list
    //     })
    //   })
    //
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    //  if (this.props !== undefined && this.props.companies_owned !== undefined && this.props.companies_owned !== null && this.props.companies_owned[1] !== undefined && this.props.companies_owned[1] !== null && this.props.companies_owned[1][0] !== undefined && prevProps.companies_owned !== this.props.companies_owned) {
    if (
      this.props !== undefined &&
      this.props.companies_owned !== undefined &&
      this.props.companies_owned !== null &&
      this.props.companies_owned[1] !== undefined &&
      this.props.companies_owned[1] !== null &&
      this.props.companies_owned[1][0] !== undefined &&
      this.state.filters_filtered === false
    ) {
      this.setState({
        filters_filtered: true,
      });
      this.populate_filters_based_off_company();
    }
    var noCompanyRegistered =
      this.props.companies_owned !== undefined &&
      this.props.companies_owned === null &&
      this.state.filters_filtered === false;
    var showNewsFeedPopup =
      this.props.user.loading === false &&
      this.props.isLoggedIn === false &&
      this.state.filters_filtered === false &&
      this.state.url.query.industry_tag !== undefined &&
      prevProps !== this.props;
    var showRegisterPopup =
      this.props.user.loading === false &&
      this.props.isLoggedIn === true &&
      noCompanyRegistered &&
      this.state.filters_filtered === false &&
      this.state.url.query.industry_tag !== undefined &&
      prevProps !== this.props;
    if (showNewsFeedPopup) {
      this.setState(
        {
          filters: {
            target_industries: [this.state.url.query.industry_tag],
          },
          filters_filtered: true,
        },
        function () {
          this.filterList(this.state.opportunity_list, (_this) => {
            _this.getInfo(
              _this.state.opportunity_list.filter((company) =>
                _this.state.opportunity_ids.includes(company._id),
              ),
            );
          });
        },
      );
    }
    if (showRegisterPopup) {
      this.setState(
        {
          filters: {
            target_industries: [this.state.url.query.industry_tag],
          },
          filters_filtered: true,
        },
        function () {
          this.filterList(this.state.opportunity_list, (_this) => {
            _this.getInfo(
              _this.state.opportunity_list.filter((company) =>
                _this.state.opportunity_ids.includes(company._id),
              ),
            );
          });
        },
      );
    }
  }

  populate_filters_based_off_company = () => {
    let filters =
      this.state.url.query.industry_tag !== undefined &&
      this.state.url.query.industry_tag !== null &&
      this.state.url.query.industry_tag.length > 0
        ? [this.state.url.query.industry_tag]
        : this.props.companies_owned[1][0].industry;
    this.setState(
      {
        filters_filtered: true,
        filters: {
          target_industries: filters,
          geographical_location: [this.props.companies_owned[1][0].country],
          target_stage_companies: [this.props.companies_owned[1][0].stage],
        },
      },
      function () {
        this.filterList(this.state.opportunity_list, (_this) => {
          _this.getInfo(
            _this.state.opportunity_list.filter((company) =>
              _this.state.opportunity_ids.includes(company._id),
            ),
          );
        });
      },
    );
  };

  validateAvatar = (id) => {
    if (this.state.showLoader === false) {
      if (
        this.state.opportunity_list[id].company_files.length > 0 &&
        this.state.opportunity_list[id].company_files[0].file_type ===
          "company avatar"
      ) {
        return this.state.opportunity_list[id].company_files.filter(
          (file) => file.file_type === "company avatar",
        )[0];
      } else {
        return default_pic;
      }
    }
  };

  getInfo = (data) => {
    let getLowerCase = function (input) {
      if (typeof input === "number") {
        return input.toString();
      } else if (typeof input === "string") {
        return input.toLowerCase();
      } else {
        return false;
      }
    };

    let search_item = this.state.query_search.toLowerCase();
    let results = [];
    let extractKeyValues = (search_term, obj_input) => {
      for (let key in obj_input) {
        // skip loop if the property is from prototype
        if (!obj_input.hasOwnProperty(key)) continue;

        if (typeof obj_input[key] === "object") {
          let obj = obj_input[key];
          for (let prop in obj) {
            if (!obj.hasOwnProperty(prop)) continue;
            let str = getLowerCase(obj[prop]);
            if (str && str.includes(search_term)) {
              return true;
            }
          }
        } else {
          let str = getLowerCase(obj_input[key]);
          if (str && str.includes(search_term)) {
            return true;
          }
        }
      }
    };

    for (let i = 0; i < data.length; i++) {
      if (extractKeyValues(search_item, data[i])) {
        results.push(data[i]._id);
      }
    }
    this.setState({
      opportunity_ids: results,
    });
  };

  getAvatarUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    } else {
      return default_pic;
    }
  };

  togglePopup = (e) => {
    this.setState({
      showSignInPopup: !this.state.showSignInPopup,
    });
  };

  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  getDiversity = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  resetFilters = () => {
    this.setState(
      {
        filters: {},
      },
      function () {
        this.filterList(this.state.opportunity_list, (_this) => {
          _this.getInfo(
            _this.state.opportunity_list.filter((company) =>
              _this.state.opportunity_ids.includes(company._id),
            ),
          );
        });
      },
    );
  };

  handleSingleDelete = (data, key) => () => {
    let deleteKey = (object, key) => {
      delete object[key];
      return {
        ...object,
      };
    };
    let filtersx = this.state.filters[key];
    let chipToDelete = filtersx.indexOf(data);
    filtersx.splice(chipToDelete, 1);
    this.setState(
      {
        filters:
          filtersx.length > 0
            ? {
                ...this.state.filters,
                [key]: filtersx,
              }
            : deleteKey(this.state.filters, key),
      },
      function () {
        this.filterList(this.state.opportunity_list, (_this) => {
          _this.getInfo(
            _this.state.opportunity_list.filter((company) =>
              _this.state.opportunity_ids.includes(company._id),
            ),
          );
        });
      },
    );
  };

  handleFilterChange = (e) => {
    let deleteKey = (object, key) => {
      delete object[key];
      return {
        ...object,
      };
    };
    const name = e.target.name;
    this.setState(
      {
        filters:
          e.target.value.length > 0
            ? {
                ...this.state.filters,
                [name]: e.target.value,
              }
            : deleteKey(this.state.filters, name),
      },
      function () {
        this.filterList(this.state.opportunity_list, (_this) => {
          _this.getInfo(
            _this.state.opportunity_list.filter((company) =>
              _this.state.opportunity_ids.includes(company._id),
            ),
          );
        });
      },
    );
  };

  handleMenuChange = (event, value) => {
    this.setState(
      {
        menuValue: value,
      },
      () => {
        if (this.state.menuValue === 1) {
          axios
            .post("/fetch_multiple_directories_archive_no_tag", {
              opportunity_type: [
                "Debt Financing",
                "Venture Capital Fund",
                "Angel Groups",
              ],
            })
            .then((response) => {
              this.setState(
                {
                  opportunity_list: response.data.result,
                },
                () => {
                  this.filterList(this.state.opportunity_list, (_this) => {
                    _this.getInfo(
                      _this.state.opportunity_list.filter((company) =>
                        _this.state.opportunity_ids.includes(company._id),
                      ),
                    );
                  });
                },
              );
            });
        } else {
          this.setState(
            {
              opportunity_list: this.state.active_comps,
            },
            () => {
              this.filterList(this.state.opportunity_list, (_this) => {
                _this.getInfo(
                  _this.state.opportunity_list.filter((company) =>
                    _this.state.opportunity_ids.includes(company._id),
                  ),
                );
              });
            },
          );
        }
      },
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <SiteTitle
          title="StartupFuel - Funding"
          meta_description="Find relevant funding opportunities for your startup to apply to."
          meta_keywords="Funding, Angel groups, investment, VC, Venture Capital, Pitch, StartupFuel, Startup, Startups, Pitch, Investors, Accelerators, Startup Services"
        />
        <Navigation />
        <Sign_In />
        <Forgot_Password />
        <Alert_Message_To_User
          show={this.props.showPopupMessage}
          handleChange={this.props.handleChange}
          dismiss={this.props.dismissModal}
          alert_message={this.props.alert_message}
          {...this.state}
        />
        <Register />
        <SideMenu />
        <div className="ExpOppTooMainUbaid">
          <div className="row">
            <div className="UbaidRightFilter filter-explore-page-new">
              {/* <div> */}
              <Filters
                handleFilterChange={this.handleFilterChange}
                filters={this.state.filters}
                handleSingleDelete={this.handleSingleDelete}
                getStage={this.getStage}
                resetFilters={this.resetFilters}
              />
              <Footer />
              {/* </div> */}
            </div>
            <div class="UbaidLeftOppExp">
              <div className="card OppServiceBoxCard">
                <div className="OppServiceBox">
                  {this.state.opportunity_ids !== undefined && (
                    <h5 className="NumberOfPitchCompUBaid">
                      {this.state.opportunity_ids.length}{" "}
                      {this.state.opportunity_ids.length <= 1
                        ? "Funding Opportunity"
                        : "Funding Opportunities"}{" "}
                      Available
                    </h5>
                  )}
                  <BottomNavigation
                    className="BottomNavigationActionUBaid"
                    value={this.state.menuValue}
                    onChange={this.handleMenuChange}
                    showLabels
                  >
                    <BottomNavigationAction
                      label="Current"
                      value={0}
                      icon={<EventLive />}
                    />
                    <BottomNavigationAction
                      label="Archive"
                      value={1}
                      icon={<EventDead />}
                    />
                  </BottomNavigation>
                  <div class="hidden-sm-down UbaidSortByOpp">
                    <SortBy
                      sortby={this.state.sortby}
                      handleChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              {this.state.showLoader === true && (
                <div
                  style={{
                    margin: "10% auto 0px -100px",
                    position: "relative",
                    left: "50%",
                  }}
                >
                  <Loader />
                </div>
              )}
              <div className="row ubaidOppCards">
                {this.state.showLoader === false &&
                  this.state.opportunity_list
                    .filter((company) =>
                      this.state.opportunity_ids.includes(company._id),
                    )
                    .map((company, index) => (
                      <div className="ubaidExploreCards col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <Opportunity_Card
                          getStage={this.getStage}
                          getAvatarUrl={this.getAvatarUrl}
                          getDiversity={this.getDiversity}
                          user={this.props.user}
                          {...company}
                          index={index}
                        />
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
Opportunities_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Opportunities_Container;
