import React, { useEffect, useRef, useState } from "react";
import { stage_list } from "../../../../../boilerplate/list.json";
import default_pic from "../../../../../img/default_company_pic.png";
import { TypeAnimation } from "react-type-animation";
import "../tearsheet.css";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

const EditableText = ({
  value,
  onChange,
  triggerResize,
  showTypingAnimation,
  onAnimationComplete,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const textareaRef = useRef(null);

  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (isEditing) {
      resizeTextarea();
    }
  }, [isEditing, value, triggerResize]);

  return (
    <>
      {isEditing ? (
        <textarea
          ref={textareaRef}
          style={{ width: "100%", height: "auto", overflow: "hidden" }}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            resizeTextarea();
          }}
          onBlur={() => setIsEditing(false)}
          autoFocus
        />
      ) : (
        <div onClick={() => setIsEditing(true)}>
          {value && showTypingAnimation ? (
            <TypeAnimation
              sequence={[
                value,
                20000, // Optional: Add a delay if you want the text to stay for a while before completing
                () => {
                  onAnimationComplete(); // Call the onComplete handler to update the state
                },
              ]}
              wrapper="span"
              cursor={true}
              speed={85}
              repeat={0}
              style={{ whiteSpace: "pre-line" }}
            />
          ) : (
            value || "Click to edit"
          )}
        </div>
      )}
    </>
  );
};

const TearsheetItem = ({
  tearsheet,
  index,
  company,
  downloadPdf,
  isDownloading,
  handleExpand,
  isExpanded,
  saveContent,
  isSaving,
  totalTearSheets, // pass the total count as a prop
}) => {
  const [content, setContent] = useState(tearsheet.tearsheet);
  const [triggerResize, setTriggerResize] = useState(false);
  const [showTypingAnimation, setShowTypingAnimation] = useState(false);
  const pdfRef = useRef(null);

  const handleAnimationComplete = () => {
    setShowTypingAnimation(false);
  };

  const handleHighlightChange = (index, newValue) => {
    const newHighlights = [...content.KeyHighlights];
    newHighlights[index] = newValue;
    setContent({ ...content, KeyHighlights: newHighlights });
  };

  // Calculate the correct number to display
  const displayNumber = totalTearSheets - index;

  return (
    <div className="report-container" key={index}>
      <div className="overall-summary" onClick={() => handleExpand(index)}>
        <h2 className="overall-score">
          Tearsheet {displayNumber}{" "}
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </h2>
        <div className="score-details-right">
          <p className="credits-used">Credits Used: {tearsheet.credits_used}</p>
          <p className="created-on">
            Date Created: {new Date(tearsheet.created_on).toLocaleDateString()}
          </p>
          <button
            className="button button-secondary"
            disabled={isDownloading}
            onClick={() => downloadPdf(index, pdfRef, company.company_name)}
          >
            {isDownloading ? "Downloading..." : "Download Tearsheet"}
          </button>
        </div>
      </div>

      {isExpanded && (
        <>
          <div className="header" style={{ marginTop: "20px" }}>
            <button
              className="button button-secondary"
              disabled={isSaving}
              onClick={() => saveContent(content)}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
          <div ref={pdfRef} className="pdf-content">
            <div className="company-overview">
              <div className="company-info">
                <div>
                  <h2 className="tearsheet-title">Startup Tear sheet</h2>
                  Investment Opportunity | Powered by DiligenceGPT
                  <p>
                    {company.company_name}
                    {company.company_website && (
                      <a
                        href={
                          company.company_website.startsWith("http")
                            ? company.company_website
                            : `http://${company.company_website}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        | {company.company_website}
                      </a>
                    )}
                  </p>
                </div>
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/startupfuel-logo.png`}
                    alt="StartupFuel Logo"
                    style={{ height: 50 }}
                  />
                  <p>Venture Ratings Agency</p>
                  <p>
                    Date-created:{" "}
                    {new Date(tearsheet.created_on).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div className="tearsheet-section">
                <div className="company-info">
                  <div className="company-details">
                    <img
                      src={default_pic}
                      alt={`${company.company_name} Avatar`}
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                  <div className="company-details">
                    <div>
                      Founded
                      <p style={{ fontWeight: "bold" }}>
                        {company.founded_year ? company.founded_year : "-"}
                      </p>
                    </div>
                    <div>
                      Location
                      <p style={{ fontWeight: "bold" }}>{company.country}</p>
                    </div>
                  </div>
                  <div className="company-details">
                    <div>
                      Business Model(s)
                      <p style={{ fontWeight: "bold" }}>
                        {company.business_model}
                      </p>
                    </div>
                    <div>
                      Primary Industry
                      <p style={{ fontWeight: "bold" }}>
                        {company.industry[0]}
                      </p>
                    </div>
                  </div>
                  <div className="company-details">
                    <div>
                      Business Stage
                      <p style={{ fontWeight: "bold" }}>
                        {stage_list.find(
                          (stage) => stage.value === company.stage,
                        )?.text || "N/A"}
                      </p>
                    </div>
                    <div>
                      Business Type
                      <p style={{ fontWeight: "bold" }}>
                        {company.company_type[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: "20px" }}>
              <div className="business-overview">
                <h2>Business Overview</h2>
                <EditableText
                  value={content.Overview}
                  onChange={(value) =>
                    setContent({ ...content, Overview: value })
                  }
                  triggerResize={triggerResize}
                  showTypingAnimation={showTypingAnimation}
                  onAnimationComplete={handleAnimationComplete}
                />
                <span style={{ fontWeight: "bold" }}>Problem:</span>
                <EditableText
                  value={content.Problem}
                  onChange={(value) =>
                    setContent({ ...content, Problem: value })
                  }
                  triggerResize={triggerResize}
                  showTypingAnimation={showTypingAnimation}
                  onAnimationComplete={handleAnimationComplete}
                />
                <span style={{ fontWeight: "bold" }}>Solution:</span>
                <EditableText
                  value={content.Solution}
                  onChange={(value) =>
                    setContent({ ...content, Solution: value })
                  }
                  triggerResize={triggerResize}
                  showTypingAnimation={showTypingAnimation}
                  onAnimationComplete={handleAnimationComplete}
                />
                <span style={{ fontWeight: "bold" }}>Market:</span>
                <EditableText
                  value={content.Market}
                  onChange={(value) =>
                    setContent({ ...content, Market: value })
                  }
                  triggerResize={triggerResize}
                  showTypingAnimation={showTypingAnimation}
                  onAnimationComplete={handleAnimationComplete}
                />
                <span style={{ fontWeight: "bold" }}>Traction:</span>
                <EditableText
                  value={content.Traction}
                  onChange={(value) =>
                    setContent({ ...content, Traction: value })
                  }
                  triggerResize={triggerResize}
                  showTypingAnimation={showTypingAnimation}
                  onAnimationComplete={handleAnimationComplete}
                />
                <span style={{ fontWeight: "bold" }}>Advantage:</span>
                <EditableText
                  value={content.Advantage}
                  onChange={(value) =>
                    setContent({ ...content, Advantage: value })
                  }
                  triggerResize={triggerResize}
                  showTypingAnimation={showTypingAnimation}
                  onAnimationComplete={handleAnimationComplete}
                />
              </div>
              <div className="key-highlights">
                <h2>Key Highlights</h2>
                <ul>
                  {content.KeyHighlights.map((highlight, index) => (
                    <li key={index}>
                      <EditableText
                        value={highlight}
                        onChange={(value) =>
                          handleHighlightChange(index, value)
                        }
                        triggerResize={triggerResize}
                        showTypingAnimation={showTypingAnimation}
                        onAnimationComplete={handleAnimationComplete}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <hr
                style={{
                  margin: "20px 0",
                  border: "none",
                  borderTop: "2px solid #000",
                  width: "100%",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p>
                  Confidential | Contact us for info on how this was derived:
                  diligence@startupfuel.com
                </p>
                <img
                  src={require("../../../../../img/favicon.png")}
                  style={{ width: 40 }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default function Tearsheet(props) {
  const [tearSheets, setTearSheets] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [expandedSheet, setExpandedSheet] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    if (props.company && props.company.aiGenerations) {
      const sortedTearSheets = [
        ...(props.company.aiGenerations.tearsheets || []),
      ].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
      setTearSheets(sortedTearSheets);
    }
  }, [props.company]);

  const downloadPdf = (index, pdfRef, companyName) => {
    if (!pdfRef.current) {
      // Expand the section if it's not expanded
      handleExpand(index);
      setTimeout(() => {
        initiateDownload(pdfRef, companyName);
      }, 300); // Allow time for the expansion animation to complete
    } else {
      initiateDownload(pdfRef, companyName);
    }
  };

  const initiateDownload = (pdfRef, companyName) => {
    setIsDownloading(true);
    const element = pdfRef.current;
    const heightPx = element.scrollHeight;
    const heightCM = heightPx / 35.35;

    const options = {
      filename: companyName + " Tearsheet | StartupFuel.pdf",
      html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
      jsPDF: {
        orientation: "portrait",
        unit: "cm",
        format: [heightCM, 29.7],
      },
    };

    html2pdf()
      .from(element)
      .set(options)
      .save()
      .then(() => setIsDownloading(false))
      .catch((error) => {
        console.error("Error generating PDF:", error);
        setIsDownloading(false);
      });
  };

  const saveContent = async (content, index) => {
    setIsSaving(true);
    try {
      await axios.post(`/save_tearsheet`, { content: content });
      const updatedTearSheets = [...tearSheets];
      updatedTearSheets[index] = {
        ...updatedTearSheets[index],
        tearsheet: content,
      };
      setTearSheets(updatedTearSheets);
      setIsSaving(false);
    } catch (error) {
      console.error("Error saving content:", error);
      setIsSaving(false);
    }
  };

  const generateTearsheet = async () => {
    const companyId = props.company.applicant_company;
    const opportunityId = props.opportunity_data._id;

    if (props.opportunity_data.credits < 1) {
      alert("Not enough credits to generate a tear sheet.");
      return;
    }

    setIsGenerating(true);

    try {
      const requestBody = {
        applicationObject: props.applicationInfo,
      };

      const response = await axios.post(
        `/fetch_tearsheet?company_id=${companyId}&opportunity_id=${opportunityId}`,
        requestBody,
      );

      const newTearSheet = {
        tearsheet: response.data.analysis.tearsheet,
        created_on: response.data.analysis.created_on,
        credits_used: response.data.analysis.credits_used,
      };

      setTearSheets((prevTearSheets) => [newTearSheet, ...prevTearSheets]);

      const updates = {
        aiGenerations: {
          ...props.company.aiGenerations,
          tearsheets: [
            newTearSheet,
            ...(props.company.aiGenerations?.tearsheets || []),
          ],
        },
      };

      props.updateCompanyData(companyId, updates);
      props.handleCreditChange(-1);
      setExpandedSheet(0);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        alert(
          "Tearsheet generation failed. No pitch deck found. Please upload a pitch deck to generate a tear sheet.",
        );
      } else {
        console.error("Error generating tear sheet:", error);
        alert("An unexpected error occurred while generating the tear sheet.");
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const handleExpand = (index) => {
    setExpandedSheet(expandedSheet === index ? null : index);
  };

  return (
    <div className="px-3">
      <h4>Tear sheet</h4>
      <p className="text-grey text-sm">
        Generate a Startup Tearsheet using the patent pending StartupFuel
        DiligenceGPT Model.
      </p>
      <button
        className="summarize-button"
        onClick={generateTearsheet}
        disabled={isGenerating}
      >
        {isGenerating ? "Generating..." : "Generate Tearsheet"}
      </button>
      {tearSheets.map((tearsheet, index) => (
        <TearsheetItem
          key={tearsheet.created_on} // Use a unique key
          tearsheet={tearsheet}
          index={index}
          company={props.company}
          downloadPdf={downloadPdf}
          isDownloading={isDownloading}
          handleExpand={handleExpand}
          isExpanded={expandedSheet === index}
          saveContent={(content) => saveContent(content, index)}
          isSaving={isSaving}
          totalTearSheets={tearSheets.length}
        />
      ))}
    </div>
  );
}
