import React from "react";
import URL from "url-parse";
import axios from "axios";
import { Card, Form } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import SimpleCard from "./simple_card";
import SimpleMultiCard from "./simple_card_multi";
import MultiSelectCard from "./simple_multi_select_card";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import "../file_upload.css";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Opportunity_Card from "./opportunity_card_preview";
import default_pic from "../../../img/default_company_pic.png";
import { create_opp_questions } from "./opportunity_lists";

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 20,
  },
  bar: {
    borderRadius: 20,
  },
})(LinearProgress);

class SocialOnboardingView extends React.Component {
  constructor(props) {
    super(props);
    this.REQUIRED_FOLLOWS = 3;
    this.state = {
      url: new URL(window.location.href, true),
      feed: [],
      loading: true,
      newFollowCount: 0,
      create_opp_questions: [],
      count: 0,

      start_intake_date: new Date(),
      start_intake_time: new Date(),
      end_intake_date: new Date(),
      end_intake_time: new Date(),

      backgroundDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
      file: "",
      opportunity_type: "",
      opportunity_type_old: "",
      affiliated_company: "",
      opportunity_name: "",
      target_industries: [],
      target_stage_companies: [],
      target_model_companies: [],
      target_distinctions: [],
      opportunity_description: "",
      website_url: "",
      deadline: "",
      geographical_location: [],
      venue_location: "",
      start_date: "",
      end_date: "",
      showSignInPopup: false,
      fields: "",
      select_all: false,
      showSuccessPopover: false,
      ticket_price_checked: false,
      ticket_price: 0,
      expiration_date_checked: false,
      venue_checked: false,
      start_end_checked: false,
      has_applicant_room: "",
      prize_checked: false,
      prize: "",
      cheque_size_checked: false,
      min_cheque_size: 0,
      max_cheque_size: 0,
      company_list: [],
      errors: {},
      file_temp: "",
      imagePreviewUrl: "",
    };
  }
  fetchAssets = (asset_type, callback) => {
    axios
      .get(`/fetch_${asset_type}`)
      .then((response) => {
        callback(response.data, this);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidUpdate(prevProps) {
    if (
      Array.isArray(this.props.companies_owned) &&
      prevProps.companies_owned !== this.props.companies_owned
    ) {
      if (this.props.companies_owned.length > 1) {
        this.setState(
          {
            affiliated_company: this.props.companies_owned[1][0]._id,
          },
          () => {
            axios
              .get(
                `fetch_company_files?company_id=${this.state.affiliated_company}`,
              )
              .then((response) => {
                if (
                  response.data.status === "success" ||
                  response.data.status === "partial"
                ) {
                  this.setState({
                    company_files: response.data.files,
                  });
                }
              });
            this.fetchAssets(
              `opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
              function (data, _this) {
                if (data.status === "success" || data.status === "partial") {
                  _this.setState(
                    {
                      loading: false,
                      ...data.result,
                    },
                    () => {
                      _this.setState({
                        create_opp_questions: create_opp_questions.filter((q) =>
                          q.applicable_to.includes(
                            _this.state.opportunity_type,
                          ),
                        ),
                      });
                    },
                  );
                }
              },
            );
          },
        );
      }
    }
  }
  handleFileSubmit = (opp_id) => {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();

    for (let key in this.state.backgroundDetails) {
      formData.append(key, this.state.backgroundDetails[key]);
    }
    if (typeof this.state.file === "object") {
      axios
        .post(`/upload_opp_file?opp_id=${opp_id}`, formData, config)
        .then((response) => {
          this.props.history.push(`/opportunity?opportunity_id=${opp_id}`);
        });
    }
  };

  handleInputSubmit = (e) => {
    e.preventDefault();

    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();

    for (let key in this.state) {
      if (key !== "file_temp" && key !== "file") {
        formData.append(key, this.state[key]);
      }
    }

    axios
      .post(
        `/edit_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
        formData,
        config,
      )
      .then((response) => {
        if (typeof this.state.file === "object") {
          this.handleFileSubmit(response.data.result._id);
        }
        this.props.history.push(
          `/opportunity?opportunity_id=${response.data.result._id}`,
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.setState({
      create_opp_questions: create_opp_questions,
      loading: false,
    });
  }
  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCardSelect = (e, value, answer) => {
    e.preventDefault();
    if (this.state[value] !== answer && value === "opportunity_type") {
      this.setState({
        [value]: answer,
        create_opp_questions: create_opp_questions.filter((q) =>
          q.applicable_to.includes(answer),
        ),
      });
    } else if (this.state[value] !== answer) {
      this.setState({
        [value]: answer,
      });
    } else {
      this.setState({
        [value]: "",
      });
    }
  };
  handleMultiCardSelect = (e, value, answer) => {
    e.preventDefault();
    if (this.state[value].includes(answer)) {
      let x = this.state[value];
      this.setState({
        [value]: x.filter((val) => {
          return val !== answer;
        }),
      });
    } else {
      let x = this.state[value];
      x.push(answer);
      this.setState({
        [value]: x,
      });
    }
  };

  selectAll = (e, value, list) => {
    e.preventDefault();
    let array = [];
    if (this.state[value].length !== list.length) {
      list.map((item) => {
        array.push(item.value);
        if (array.length === list.length) {
          this.setState({
            [value]: array,
          });
        }
      });
    } else {
      this.setState({
        [value]: [],
      });
    }
  };

  handleDateChange = (e, db_value1) => {
    this.setState(
      {
        start_intake_time: new Date(this.state.start_intake_time),
        end_intake_time: new Date(this.state.end_intake_time),
        start_intake_date: new Date(this.state.start_intake_date),
        end_intake_date: new Date(this.state.end_intake_date),
      },
      () => {
        if (Date.parse(e) > 0) {
          if (db_value1 === "start_intake_date") {
            var datetime = new Date(
              e.getFullYear(),
              e.getMonth(),
              e.getDate(),
              this.state.start_intake_time.getHours(),
              this.state.start_intake_time.getMinutes(),
              this.state.start_intake_time.getSeconds(),
            );
            this.setState({
              [db_value1]: datetime,
              start_intake_time: datetime,
            });
          } else if (db_value1 === "end_intake_date") {
            var datetime = new Date(
              e.getFullYear(),
              e.getMonth(),
              e.getDate(),
              this.state.end_intake_time.getHours(),
              this.state.end_intake_time.getMinutes(),
              this.state.end_intake_time.getSeconds(),
            );
            this.setState({
              [db_value1]: datetime,
              end_intake_time: datetime,
            });
          } else if (db_value1 === "start_intake_time") {
            var datetime = new Date(
              this.state.start_intake_date.getFullYear(),
              this.state.start_intake_date.getMonth(),
              this.state.start_intake_date.getDate(),
              e.getHours(),
              e.getMinutes(),
              e.getSeconds(),
            );
            this.setState({
              [db_value1]: datetime,
              start_intake_date: datetime,
            });
          } else if (db_value1 === "end_intake_time") {
            var datetime = new Date(
              this.state.end_intake_date.getFullYear(),
              this.state.end_intake_date.getMonth(),
              this.state.end_intake_date.getDate(),
              e.getHours(),
              e.getMinutes(),
              e.getSeconds(),
            );
            this.setState({
              [db_value1]: datetime,
              end_intake_date: datetime,
            });
          }
        } else {
        }
      },
    );
  };
  handleToggle = (e, db_value, value) => {
    e.preventDefault();
    if (this.state[db_value] !== value) {
      this.setState({
        [db_value]: value,
      });
    } else {
      this.setState({
        [db_value]: "",
      });
    }
  };

  getAvatarUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    } else {
      return default_pic;
    }
  };
  handleFileUpload = (e) => {
    let file = e.target.files[0];
    // e.persist()
    this.setState({
      backgroundDetails: {
        file,
        file_name: "opp background",
        file_type: `opp image`,
        object_ref: "opportunity",
      },
      file: file,
    });
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
    this.handleFileUpload(e);
  }

  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value.toString() === value.toString()) {
        return array[i].text;
      }
    }
  };

  getDiversity = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  handleNext(e) {
    e.preventDefault();
    this.setState({
      count: this.state.count + 1,
    });
  }
  handleBack(e) {
    e.preventDefault();
    this.setState({
      count: this.state.count - 1,
    });
  }

  render() {
    return (
      <>
        {this.state.loading !== true && (
          <div className="SocialOnboarding">
            <div className="row">
              <div className="col">
                <h3>
                  {this.state.count < this.state.create_opp_questions.length &&
                    this.state.create_opp_questions[this.state.count].title}

                  {this.state.count < this.state.create_opp_questions.length &&
                    (this.state.create_opp_questions[this.state.count].type ===
                      "multi_card_select" ||
                      this.state.create_opp_questions[this.state.count].type ===
                        "card_select_multi") && (
                      <Button
                        style={{ float: "right" }}
                        size="small"
                        color="primary"
                        className={
                          this.state[
                            this.state.create_opp_questions[this.state.count]
                              .db_value
                          ].length ===
                          this.state.create_opp_questions[this.state.count]
                            .options.length
                            ? "UbaidUnfollow"
                            : "ubaidFollow"
                        }
                        onClick={(event) =>
                          this.selectAll(
                            event,
                            this.state.create_opp_questions[this.state.count]
                              .db_value,
                            this.state.create_opp_questions[this.state.count]
                              .options,
                          )
                        }
                      >
                        {this.state[
                          this.state.create_opp_questions[this.state.count]
                            .db_value
                        ].length ===
                        this.state.create_opp_questions[this.state.count]
                          .options.length
                          ? "Unselect All"
                          : "Select All"}
                      </Button>
                    )}
                </h3>
                {/*<h5>Please follow a minimum of { this.REQUIRED_FOLLOWS } companies before continuing:</h5> */}
                <br />
                <BorderLinearProgress
                  style={{ "flex-grow": 1 }}
                  variant="determinate"
                  color="primary"
                  value={Math.max(
                    0,
                    this.state.count *
                      (100.0 /
                        (this.state.opportunity_type.length === 0
                          ? 1
                          : this.state.create_opp_questions.length - 1)),
                  )}
                  //value={ Math.max(0, ((this.state.count) * (100.0 / create_opp_questions.find(q=>q.includes(this.state.opportunity_type))))) }
                />
                <br />
              </div>
            </div>
            <div className="row ubaidOppCards">
              {this.state.count < this.state.create_opp_questions.length &&
                this.state.create_opp_questions[this.state.count].type ===
                  "card_select" &&
                (() => {
                  // Categories to display separately
                  const categories = {
                    "Venture Capital Fund": "Venture Capital Fund",
                    Accelerator: "Accelerator Intake",
                    "Angel Groups": "Angel Groups",
                  };

                  const mostUsed = [
                    "Venture Capital Fund",
                    "Accelerator",
                    "Angel Groups",
                  ];

                  const categorizedOptions = {};
                  const mostUsedOptions = [];
                  const otherOptions = [];

                  // Separate options into categorized, most used, and other
                  this.state.create_opp_questions[
                    this.state.count
                  ].options.forEach((option) => {
                    if (mostUsed.includes(option.value)) {
                      mostUsedOptions.push(option);
                    } else if (categories[option.value]) {
                      if (!categorizedOptions[option.value]) {
                        categorizedOptions[option.value] = [];
                      }
                      categorizedOptions[option.value].push(option);
                    } else {
                      otherOptions.push(option);
                    }
                  });

                  return (
                    <>
                      {/* Render "Most Used" section */}
                      {mostUsedOptions.length > 0 && (
                        <div>
                          <h5>Most Used</h5>
                          <div className="row">
                            {mostUsedOptions.map((option, index) => (
                              <div
                                key={index}
                                className="ubaidExploreCards col-xl-4 col-lg-4 col-md-6 col-sm-12 UbaidCreatePitchOpp"
                              >
                                <SimpleCard
                                  no_avatar={true}
                                  {...option}
                                  db_value={
                                    this.state.create_opp_questions[
                                      this.state.count
                                    ].db_value
                                  }
                                  current_state_value={
                                    this.state[
                                      this.state.create_opp_questions[
                                        this.state.count
                                      ].db_value
                                    ]
                                  }
                                  handleCardSelect={this.handleCardSelect}
                                  index={index}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {/* Render categorized sections */}
                      {Object.keys(categorizedOptions).map((categoryKey) => (
                        <div key={categoryKey}>
                          <h5>{categories[categoryKey]}</h5>
                          <div className="row">
                            {categorizedOptions[categoryKey].map(
                              (option, index) => (
                                <div
                                  key={index}
                                  className="ubaidExploreCards col-xl-4 col-lg-4 col-md-6 col-sm-12 UbaidCreatePitchOpp"
                                >
                                  <SimpleCard
                                    no_avatar={true}
                                    {...option}
                                    db_value={
                                      this.state.create_opp_questions[
                                        this.state.count
                                      ].db_value
                                    }
                                    current_state_value={
                                      this.state[
                                        this.state.create_opp_questions[
                                          this.state.count
                                        ].db_value
                                      ]
                                    }
                                    handleCardSelect={this.handleCardSelect}
                                    index={index}
                                  />
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      ))}

                      {/* Render "Other" section */}
                      <div>
                        <h5
                          onClick={() =>
                            this.setState({
                              isOtherOpen: !this.state.isOtherOpen,
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Other {this.state.isOtherOpen ? "▲" : "▼"}
                        </h5>
                        {this.state.isOtherOpen && (
                          <div className="row">
                            {otherOptions.map((option, index) => (
                              <div
                                key={index}
                                className="ubaidExploreCards col-xl-4 col-lg-4 col-md-6 col-sm-12 UbaidCreatePitchOpp"
                              >
                                <SimpleCard
                                  no_avatar={true}
                                  {...option}
                                  db_value={
                                    this.state.create_opp_questions[
                                      this.state.count
                                    ].db_value
                                  }
                                  current_state_value={
                                    this.state[
                                      this.state.create_opp_questions[
                                        this.state.count
                                      ].db_value
                                    ]
                                  }
                                  handleCardSelect={this.handleCardSelect}
                                  index={index}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </>
                  );
                })()}
              {this.state.count < this.state.create_opp_questions.length &&
                this.state.create_opp_questions[this.state.count].type ===
                  "card_select_multi" &&
                this.state.create_opp_questions[this.state.count].options.map(
                  (option, index) => (
                    <div
                      key={index}
                      className="ubaidExploreCards col-xl-4 col-lg-4 col-md-6 col-sm-12 UbaidCreatePitchOpp"
                    >
                      <SimpleMultiCard
                        {...option}
                        db_value={
                          this.state.create_opp_questions[this.state.count]
                            .db_value
                        }
                        current_state_value={
                          this.state[
                            this.state.create_opp_questions[this.state.count]
                              .db_value
                          ]
                        }
                        handleCardSelect={this.handleMultiCardSelect}
                        index={index}
                      />
                    </div>
                  ),
                )}
              {this.state.count < this.state.create_opp_questions.length &&
                this.state.create_opp_questions[this.state.count].type ===
                  "single_line" && (
                  <TextField
                    id="outlined-input"
                    name={
                      this.state.create_opp_questions[this.state.count].db_value
                    }
                    label={
                      this.state.create_opp_questions[this.state.count]
                        .description
                    }
                    onChange={this.handleChange}
                    style={{ margin: "auto", width: "70%" }}
                    variant="outlined"
                    value={
                      this.state[
                        this.state.create_opp_questions[this.state.count]
                          .db_value
                      ]
                    }
                  />
                )}
              {this.state.count < this.state.create_opp_questions.length &&
                this.state.create_opp_questions[this.state.count].type ===
                  "multi_line" && (
                  <TextField
                    id="outlined-input"
                    multiline
                    rows="8"
                    name={
                      this.state.create_opp_questions[this.state.count].db_value
                    }
                    label={
                      this.state.create_opp_questions[this.state.count]
                        .description
                    }
                    onChange={this.handleChange}
                    style={{ margin: "auto", width: "70%" }}
                    variant="outlined"
                    value={
                      this.state[
                        this.state.create_opp_questions[this.state.count]
                          .db_value
                      ]
                    }
                  />
                )}
              {this.state.count < this.state.create_opp_questions.length &&
                this.state.create_opp_questions[this.state.count].type ===
                  "multi_card_select" &&
                this.state.create_opp_questions[this.state.count].options.map(
                  (option, index) => (
                    <div
                      key={index}
                      className="ubaidExploreCards col-xl-4 col-lg-4 col-md-6 col-sm-12"
                    >
                      <MultiSelectCard
                        {...option}
                        db_value={
                          this.state.create_opp_questions[this.state.count]
                            .db_value
                        }
                        current_state_value={
                          this.state[
                            this.state.create_opp_questions[this.state.count]
                              .db_value
                          ]
                        }
                        handleMultiCardSelect={this.handleMultiCardSelect}
                        index={index}
                      />
                    </div>
                  ),
                )}
              {this.state.count < this.state.create_opp_questions.length &&
                this.state.create_opp_questions[this.state.count].type ===
                  "date" && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div className="UBaidSelectMonthOpp">
                      <Card className="month-card UBaidMonthCard">
                        <Card.Body className="amount-card-body">
                          <div>
                            <div className="title">
                              <Card.Title>
                                <b>
                                  {
                                    this.state.create_opp_questions[
                                      this.state.count
                                    ].db_value1_title
                                  }
                                </b>
                              </Card.Title>
                              <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/dd/yyyy"
                                value={
                                  this.state[
                                    this.state.create_opp_questions[
                                      this.state.count
                                    ].db_value1
                                  ]
                                }
                                onChange={(event) =>
                                  this.handleDateChange(
                                    event,
                                    this.state.create_opp_questions[
                                      this.state.count
                                    ].db_value1,
                                  )
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>

                      <Card className="month-card UBaidMonthCard">
                        <Card.Body className="amount-card-body">
                          <div>
                            <div className="title">
                              <Card.Title>
                                <b>
                                  {
                                    this.state.create_opp_questions[
                                      this.state.count
                                    ].db_value2_title
                                  }
                                </b>
                              </Card.Title>

                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker"
                                value={
                                  this.state[
                                    this.state.create_opp_questions[
                                      this.state.count
                                    ].db_value2
                                  ]
                                }
                                onChange={(event) =>
                                  this.handleDateChange(
                                    event,
                                    this.state.create_opp_questions[
                                      this.state.count
                                    ].db_value2,
                                  )
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change time",
                                }}
                              />
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </MuiPickersUtilsProvider>
                )}

              {this.state.count < this.state.create_opp_questions.length &&
                this.state.create_opp_questions[this.state.count].type ===
                  "single_line_multi" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "70%",
                      margin: "auto",
                    }}
                  >
                    <TextField
                      id="outlined-min-cheque"
                      name={
                        this.state.create_opp_questions[this.state.count]
                          .db_value1
                      }
                      label={
                        this.state.create_opp_questions[this.state.count]
                          .db_value1_title
                      }
                      type="number"
                      onChange={this.handleChange}
                      variant="outlined"
                      value={
                        this.state[
                          this.state.create_opp_questions[this.state.count]
                            .db_value1
                        ]
                      }
                    />
                    <TextField
                      id="outlined-max-cheque"
                      name={
                        this.state.create_opp_questions[this.state.count]
                          .db_value2
                      }
                      label={
                        this.state.create_opp_questions[this.state.count]
                          .db_value2_title
                      }
                      type="number"
                      onChange={this.handleChange}
                      variant="outlined"
                      value={
                        this.state[
                          this.state.create_opp_questions[this.state.count]
                            .db_value2
                        ]
                      }
                    />
                  </div>
                )}
              {this.state.count < this.state.create_opp_questions.length &&
                this.state.create_opp_questions[this.state.count].type ===
                  "toggle" && (
                  <div className="UBaidSelectMonthOpp UBaidSelectFollowUn">
                    <Card
                      className={
                        this.state[
                          this.state.create_opp_questions[this.state.count]
                            .db_value
                        ] ===
                        this.state.create_opp_questions[this.state.count]
                          .option_1
                          ? "UbaidUnfollow"
                          : "ubaidFollow"
                      }
                      onClick={(event) =>
                        this.handleToggle(
                          event,
                          this.state.create_opp_questions[this.state.count]
                            .db_value,
                          this.state.create_opp_questions[this.state.count]
                            .option_1,
                        )
                      }
                    >
                      <Card.Body className="amount-card-body">
                        <div>
                          <div className="title">
                            <Card.Title>
                              <b>
                                {
                                  this.state.create_opp_questions[
                                    this.state.count
                                  ].option_1_header
                                }
                              </b>
                            </Card.Title>
                            <Card.Text style={{ fontSize: "1.1rem" }}>
                              {
                                this.state.create_opp_questions[
                                  this.state.count
                                ].option_1_body
                              }
                            </Card.Text>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>

                    <Card
                      className={
                        this.state[
                          this.state.create_opp_questions[this.state.count]
                            .db_value
                        ] ===
                        this.state.create_opp_questions[this.state.count]
                          .option_2
                          ? "UbaidUnfollow"
                          : "ubaidFollow"
                      }
                      onClick={(event) =>
                        this.handleToggle(
                          event,
                          this.state.create_opp_questions[this.state.count]
                            .db_value,
                          this.state.create_opp_questions[this.state.count]
                            .option_2,
                        )
                      }
                    >
                      <Card.Body className="amount-card-body">
                        <div>
                          <div className="title">
                            <Card.Title>
                              <b>
                                {
                                  this.state.create_opp_questions[
                                    this.state.count
                                  ].option_2_header
                                }
                              </b>
                            </Card.Title>
                            <Card.Text style={{ fontSize: "1.1rem" }}>
                              {
                                this.state.create_opp_questions[
                                  this.state.count
                                ].option_2_body
                              }
                            </Card.Text>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                )}

              {this.state.count < this.state.create_opp_questions.length &&
                this.state.create_opp_questions[this.state.count].type ===
                  "image" && (
                  <>
                    <div className="Ubaidform_file_upload">
                      <div className="label_file_upload">
                        Upload image (recommended size: 550x290)
                      </div>
                      <div className="file-upload">
                        <Form.Control
                          type="file"
                          accept=".gif,.jpg,.jpeg,.png"
                          name="file"
                          data-file-type="background"
                          onChange={(e) => this._handleImageChange(e)}
                          className="form-control mb-3"
                        />
                      </div>
                    </div>
                    {/* <div className="UBaidOppCardInOppPage">
                      <Opportunity_Card
                        {...this.state}
                        getAvatarUrl={this.getAvatarUrl}
                        getStage={this.getStage}
                      />
                      getDiversity={this.getDiversity}
                    </div> */}
                  </>
                )}
            </div>
            <AppBar
              position="fixed"
              color="default"
              style={{ top: "auto", bottom: "0" }}
            >
              <Toolbar>
                {this.state.count > 0 && (
                  <Button
                    className="ctaButton"
                    disabled={false}
                    size="large"
                    variant="contained"
                    color="green"
                    onClick={this.handleBack.bind(this)}
                  >
                    Back
                  </Button>
                )}
                {this.state.count <
                  this.state.create_opp_questions.length - 1 &&
                  this.state.create_opp_questions[this.state.count].type !==
                    "date" &&
                  this.state.create_opp_questions[this.state.count].type !==
                    "image" &&
                  this.state.create_opp_questions[this.state.count].type !==
                    "single_line_multi" && (
                    <Button
                      className="ctaButton"
                      disabled={
                        this.state[
                          this.state.create_opp_questions[this.state.count]
                            .db_value
                        ].length > 0 ||
                        typeof this.state[
                          this.state.create_opp_questions[this.state.count]
                            .db_value
                        ] === "boolean"
                          ? false
                          : true
                      }
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext.bind(this)}
                    >
                      Next
                    </Button>
                  )}
                {this.state.count <
                  this.state.create_opp_questions.length - 1 &&
                  (this.state.create_opp_questions[this.state.count].type ===
                    "date" ||
                    this.state.create_opp_questions[this.state.count].type ===
                      "image" ||
                    this.state.create_opp_questions[this.state.count].type ===
                      "single_line_multi") && (
                    <Button
                      className="ctaButton"
                      size="large"
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext.bind(this)}
                    >
                      Next
                    </Button>
                  )}
                {this.state.count ===
                  this.state.create_opp_questions.length - 1 && (
                  <Button
                    className="ctaButton"
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={this.handleInputSubmit.bind(this)}
                  >
                    Save Changes & Publish
                  </Button>
                )}
              </Toolbar>
            </AppBar>
          </div>
        )}
      </>
    );
  }
}

export default SocialOnboardingView;
