/* (2019-06-11) - This is the page that handles all of functions associated with
rating and displaying the applicants of a SIR.

(2019-09-25) - CREATED A TEMPARARY OVERALL SCORE TO ACCOUNT FOR PITCH COMPETIONS QUESTIONS OUTSIDE OF
STANDARD QUESTIONS. IT IS ONLY USED TO CALCULATE ON THE CURRENT PITCHBOX THE pitchOverallRating.
*/
import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import axios from "axios";
import URL from "url-parse";
import Profile_Card from "../../../components/application_room_card_submissions";
import default_pic from "../../../../img/default_company_pic.png";
import default_background from "../../../../img/bg.png";
// import { stage_list } from "../../../../boilerplate/list";
import { Opportunity_Judge_Share } from "../../../components/popups";
import "./submissions.scss";
import PropTypes from "prop-types";
import ThreeDotsIcon from "../../../../newsfeed/icons/ThreeDotsIcon";
import classnames from "classnames";
import ReviewsCards from "./../ReviewsCard";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import ArgNav from "../argNav";
//import Add_Company_Form from "./components/add_company_form/Add_Company_form";
import Add_Company_Form from "../../../components/add_company_form/Add_Company_form";
import { Button } from "react-bootstrap";
import MUIButton from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import ProgressTrack from "./ProgressTrack/ProgressTrack";
import IconButton from "@material-ui/core/IconButton";

import {
  Assignment,
  ArrowUpward,
  ArrowDownward,
  HighlightOff,
} from "@material-ui/icons";

import { Modal } from "react-responsive-modal";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

import { Report_Company_Flagging } from "../../../../live/components/popups";
import {
  country_list,
  industry_list,
  stage_list,
} from "../../../../boilerplate/list.json";

//import AragonLightTable from "../../../../pitch_box/ArgonDashboard/AragonBody/AragonLightTable/AragonLightTable";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

const options = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "Review",
    value: "review",
  },
  {
    label: "First Diligence",
    value: "firstdiligence",
  },
  {
    label: "Deep Diligence",
    value: "deepdilligence",
  },
  {
    label: "Offer",
    value: "offer",
  },
  {
    label: "Portco",
    value: "portco",
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

const MaxCharParagraph = ({ text, maxChars }) => {
  const truncatedText =
    text.length > maxChars ? `${text.slice(0, maxChars)}...` : text;

  return <p className="mb-0">{truncatedText}</p>;
};

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CompanyFileNew: {},
      filters: {},
      CFA: false,
      sortAscendingDate: true,
      Alfabate: "",
      reviewScore: "",
      selectedFilters: [],
      selectedFiltersCountry: {},
      selectedFiltersBmo: {},
      selectedFiltersStage: {},
      sortByColumn: "",
      fitScoreValue: "",
      matchDataFit: {},
      sortAscending: true,
      sortAscendingCFA: true,
      sortAscendingreviewScore: true,
      tabsvalue: 1,
      url: new URL(window.location.href, true),
      showSharePopup: false,
      showPopUpRate: false,
      showSideRating: false,

      // Adding Sort
      sortConfig: {
        key: null, // column index
        direction: "ascending", // ascending or descending
      },

      original_company_list: [],
      before_filter_companies: [],
      companies: [],
      activeCompany: {},
      application_status: "",
      loaded: false,

      excel_data: [],

      query_search: "",
      old_count: 0,
      results: [],
      company_ids: [],
      sort_old: "",
      sortby: "",

      selected_company_id: "",
      selected_company_id_old: "",
      flagged_companies: [],

      selected_company: "",
      showOpenReview: false,
      tabTop: 0,
      tab: 0,
      success_msg: "",
      current_judge: "",

      problem: "",
      solution: "",
      traction: "",
      idea: 5,
      technology: 5,
      marketing: 5,
      team: 5,
      overallRating: 5,
      comment: "",
      feedback: "",
      reviews: [],
      current_judge_has_reviewed: false,
      current_judge_review: {},
      totalOverall: 0,
      totalIdea: 0,
      totalTechnology: 0,
      totalMarketing: 0,
      totalTeam: 0,
      additional_review_criteria: [],
      additional_review_criteria_comment: [],
      totalAdditionalReviewCriteria: {},
      newstatus: "",
      reviewstatus: "",
      nostatusresult: [],
      firstdiligencestatus: "",
      deepdelligencestatus: "",
      offerstatus: "",
      portcostatus: "",
      tabsubmission: "",
      isOpen: false,
      isOpenTable: false,
      statusChanged: false,
      columns: [
        { title: "Company Name", field: "company_name" },
        { title: "Company Description", field: "descrip" },
        { title: "Company Website", field: "website" },
      ],
      tableCard: "table",
      showFlagPopup: false,
      popup_header: " ",
      popup_body: " ",
      applicant_rejection_email: {
        header: "Thank you for applying!",
        body: `We are sorry to inform you that we are not able to move forward with your application at this time.
              `,
        email: "",
      },
      refrecompany: false,
      updatedStatus: "",
    };
    this.updateStatus = this.updateStatus.bind(this);
    this.handleTabsSubmissionChangeNew =
      this.handleTabsSubmissionChangeNew.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleApplicationStatusChange =
      this.handleApplicationStatusChange.bind(this);
    this.openTableModal = this.openTableModal.bind(this);
    this.closeTableModal = this.closeTableModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.refreshCompanies = this.refreshCompanies.bind(this);
  }

  handleApplicationStatusChange(e) {
    console.log(e.target.value);
    this.setState({ application_status: e.target.value });
  }
  handleRejectionEmail = (e) => {
    const name = e.target.name;
    console.log("name 275", name);
    this.setState({
      applicant_rejection_email: {
        ...this.state.applicant_rejection_email,
        [name]: e.target.value,
      },
    });
  };

  sortDate = (key) => {
    const { dateData, sortAscendingDate } = this.state;
    const sortedData = dateData.sort((a, b) => {
      const dateA = new Date(a[key]);
      const dateB = new Date(b[key]);

      if (sortAscendingDate) {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    this.setState({
      dateData: sortedData,
      sortAscendingDate: !sortAscendingDate,
    });
  };

  openModal() {
    // this.setState(
    //   {
    //     filters: {},
    //   },
    //   this.fetchFeed.bind(this, true)
    // );
    console.log("open the modal");
    this.setState({ isOpen: true });
  }
  closeModal() {
    // this.setState(
    //   {
    //     filters: {},
    //   },
    //   this.fetchFeed.bind(this, true)
    // );
    console.log("close the modal");

    setTimeout(() => {
      this.setState({ isOpen: false });
    }, 150);
    setTimeout(() => {
      this.refreshCompanies();
    }, 1000);
  }

  openTableModal(company) {
    const currentStatusIndex = options.findIndex(
      (option) => option.value === company.application_status,
    );

    const nextStatusIndex = Math.min(
      currentStatusIndex + 1,
      options.length - 1,
    );
    this.setState({
      isOpenTable: true,
      activeCompany: company,
      application_status: options[nextStatusIndex].value,
    });
    //this.setState({activeCompany: company });
    // console.log(company)
  }

  closeTableModal() {
    this.setState({ isOpenTable: false });
    this.setState({
      activeCompany: {},
      statusChanged: false,
    });
  }
  refreshCompanies() {
    console.log("refresh companies");
    axios
      .all([
        axios.get(
          `fetch_submitted_companies_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
        ),
        axios.get(
          `fetch_flagged_companies_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
        ),
      ])
      .then(
        axios.spread((submittedCompaniesResponse, flaggedCompaniesResponse) => {
          if (submittedCompaniesResponse.data.status !== "None") {
            const flaggedCompanyIds =
              flaggedCompaniesResponse.data.full_company_list_with_flags.map(
                (company) => company._id,
              );
            console.log(`flaggedCompanyIds: ${flaggedCompanyIds}`);

            // Filter out flagged companies
            const unflaggedCompanies =
              submittedCompaniesResponse.data.full_company_list_with_flags.filter(
                (company) =>
                  !flaggedCompanyIds.includes(company.applicant_company),
              );

            if (unflaggedCompanies.length > 0) {
              unflaggedCompanies.sort((a, b) => {
                const date1 = new Date(a.created_on);
                const date2 = new Date(b.created_on);
                return date1 - date2;
              });
              console.log(unflaggedCompanies);

              const nostatusresult = unflaggedCompanies.filter(
                (word) => !word.application_status,
              );

              const result = unflaggedCompanies.filter(
                (word) => word.application_status === "new",
              );

              const review = unflaggedCompanies.filter(
                (word) => word.application_status === "review",
              );

              const first = unflaggedCompanies.filter(
                (word) => word.application_status === "firstdiligence",
              );

              const deep = unflaggedCompanies.filter(
                (word) => word.application_status === "deepdilligence",
              );

              const offer = unflaggedCompanies.filter(
                (word) => word.application_status === "offer",
              );

              const portco = unflaggedCompanies.filter(
                (word) => word.application_status === "portco",
              );

              console.log("got data");

              this.setState({
                nostatusresult: nostatusresult,
                newstatus: result.length,
                reviewstatus: review.length,
                firstdiligencestatus: first.length,
                deepdelligencestatus: deep.length,
                offerstatus: offer.length,
                portcostatus: portco.length,
                companies: unflaggedCompanies,
                original_company_list: unflaggedCompanies,
                flagged_companies:
                  flaggedCompaniesResponse.data.full_company_list_with_flags,
                loaded: true,
              });
            } else {
              this.setState({
                loaded: true,
                companies: [],
                original_company_list: [],
              });
            }
          } else {
            this.setState({
              loaded: true,
              companies: [],
              original_company_list: [],
            });
          }
        }),
      )
      .catch((error) => {
        console.error("Error refreshing companies:", error);
        this.setState({
          loaded: true,
          error: "Failed to refresh company data",
        });
      });
  }

  changestatus = () => {
    //e.preventDefault();
    console.log("changestatus start");
    console.log(this.state.application_status);

    const data = {
      user: this.props.user._id,
      applicant_company: this.state.activeCompany.applicant_company,
      pertains_to_opportunity: this.state.url.query.opportunity_id,
      application_status: this.state.application_status,
    };
    console.log(data);
    this.updateStatus(data);
    /*this.setState({
      isOpenTable: false,
    });*/
  };

  componentDidMount() {
    console.log("this.props.companies_owned", this.props);
    console.log("this.props.opportunity_data", this.props.opportunity_data);
    window.addEventListener("scroll", this.reviewCardHandler, true);
    this.refreshCompanies();

    axios
      .get(
        `/fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({ matchDataFit: response?.data?.result });
        console.log("response 1462", response?.data?.result);
        console.log("response?.data?.result", response?.data?.result);
        axios
          .get(
            `/fetch_company_profile?company_id=${response?.data?.result?.affiliated_company}`,
          )
          .then((response) => {
            this.setState({ CompanyFileNew: response?.data?.result[0] });
            console.log("response 4435", response?.data?.result[0]);
          });
      });
  }

  calculatePercentageForMultipleMatches(
    businessModel,
    targetModelCompanies,
    stage,
    targetStages,
    country,
    targetcountry,
    industry,
    targetindustry,
  ) {
    const businessModelMatch = targetModelCompanies?.includes(businessModel)
      ? 20
      : 0;
    const stageMatch = targetStages?.includes(stage) ? 20 : 0;
    const countryMatch = targetcountry?.includes(country) ? 20 : 0;
    const industryMatch = targetindustry?.includes(industry) ? 20 : 0;
    const totalPercentage =
      businessModelMatch + stageMatch + countryMatch + industryMatch;
    return totalPercentage;
  }

  componentDidUpdate(prevProps) {
    if (
      Array.isArray(this.props.judges) &&
      prevProps.judges !== this.props.judges
    ) {
      if (this.props.judges.some((x) => x._id === this.props.user._id)) {
        this.setState({
          current_judge: this.props.judges.filter(
            (x) => x._id === this.props.user._id,
          ),
        });
      }
    }
  }

  handleTabsSubmissionChange = (event, tabsvalue) => {
    this.setState({ tabsvalue });
  };

  handleTabsSubmissionChangeNew = (value) => () => {
    this.setState({ tabsvalue: value });
  };

  handleTableChange = (value) => () => {
    this.setState({ tableCard: value });
  };

  handleSearchInputChange = (event) => {
    const query = event.target.value.toLowerCase();
    const filteredResults = this.state.companies.filter((company) =>
      company.company_name.toLowerCase().includes(query),
    );

    this.setState({
      query_search: event.target.value,
      results: filteredResults,
    });
  };

  handleTabSubmissionsChange = (e, value) => {
    // this.setState(
    //   {
    //     tabsubmission:value
    //   })

    console.log("get the value here", value);
  };
  getInfo = () => {
    let data = this.state.companies;
    let search_item = this.state.query_search.toLowerCase();
    let results = [];
    for (let i = 0; i < data.length && i < 3; i++) {
      if (
        data[i]["company_name"] !== undefined &&
        data[i]["company_name"] !== null
      ) {
        data[i]["company_name"].toLowerCase().includes(search_item) &&
          results.push(data[i]._id);
      } else if (
        data[i]["company_type"][0] !== undefined &&
        data[i]["company_type"][0].toLowerCase().includes(search_item)
      ) {
        results.push(data[i]._id);
      } else if (
        data[i]["country"] !== undefined &&
        data[i]["country"] !== null &&
        data[i]["country"].toLowerCase().includes(search_item)
      ) {
        results.push(data[i]._id);
      } else if (
        data[i]["industry"][0] !== undefined &&
        data[i]["industry"].some((r) => r.toLowerCase().includes(search_item))
      ) {
        results.push(data[i]._id);
      } else if (
        data[i]["sub_vertical"] !== undefined &&
        data[i]["sub_vertical"].toLowerCase().includes(search_item)
      ) {
        results.push(data[i]._id);
      } else if (
        data[i]["stage"] !== undefined &&
        data[i]["stage"] !== null &&
        this.getStage(data[i]["stage"], stage_list)
          .toLowerCase()
          .includes(search_item)
      ) {
        results.push(data[i]._id);
      }
    }
    this.setState(
      {
        company_ids: results,
      },
      () => {
        this.setState(
          {
            companies: this.state.companies.filter((company) =>
              this.state.company_ids.includes(company._id),
            ),
          },
          () => {
            this.sortFunction();
          },
        );
      },
    );
  };
  handleTabTopChange = (event, value) => {
    this.setState(
      {
        tabTop: value,
      },
      () => {
        this.runFilterSequence();
      },
    );
  };
  functionTabTopChange = (callback) => {
    if (this.state.tabTop === 0) {
      this.setState(
        {
          companies: this.state.original_company_list,
        },
        () => {
          this.functionTabChange();
        },
      );
    }
    if (this.state.tabTop === 1) {
      this.setState(
        {
          companies: this.state.original_company_list.filter(
            (company) =>
              company.reviews.length <
              this.props.opportunity_data.number_of_reviews,
          ),
        },
        () => {
          this.functionTabChange();
        },
      );
    }
  };
  handleTabChange = (value) => {
    this.setState(
      {
        tab: value,
      },
      () => {
        this.runFilterSequence();
      },
    );
  };
  functionTabChange = (callback) => {
    if (this.state.tab === 0) {
      this.getInfo();
    }
    if (this.state.tab === 1) {
      this.setState(
        {
          companies: this.state.companies.filter((company) =>
            company.reviews.some(
              (review) => this.props.user._id === review.judge,
            ),
          ),
        },
        () => {
          this.getInfo();
        },
      );
    }
    if (this.state.tab === 2) {
      this.setState(
        {
          companies: this.state.companies.filter((company) =>
            company.reviews.every(
              (review) => this.props.user._id !== review.judge,
            ),
          ),
        },
        () => {
          this.getInfo();
        },
      );
    }
  };
  //Need to rewrite this sort function
  sortFunction = () => {
    if (this.props.judges.some((x) => x._id === this.props.user._id)) {
      this.setState(
        {
          current_judge: this.props.judges.filter(
            (x) => x._id === this.props.user._id,
          ),
        },
        () => {
          if (this.state.sortby === "judge_specialty") {
            this.setState({
              sort_old: this.state.sortby,
              companies: this.state.companies.filter(
                (x) =>
                  x.sub_vertical === this.state.current_judge[0].sub_vertical,
              ),
            });
          } else {
            this.setState({
              sort_old: this.state.sortby,
            });
          }
        },
      );
    }
  };

  runFilterSequence = () => {
    this.functionTabTopChange();
  };

  statusOrder = options.reduce((acc, option, index) => {
    acc[option.value] = index;
    return acc;
  }, {});

  handleSort = (key) => {
    const { sortConfig, original_company_list } = this.state;
    let direction = "ascending";

    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedResults = [...original_company_list].sort((a, b) => {
      if (key === "fit") {
        const fitA = this.handleScore(a);
        const fitB = this.handleScore(b);
        return direction === "ascending" ? fitA - fitB : fitB - fitA;
      } else if (key === "application_status") {
        const valueA =
          this.statusOrder[a[key]] !== undefined
            ? this.statusOrder[a[key]]
            : Infinity;
        const valueB =
          this.statusOrder[b[key]] !== undefined
            ? this.statusOrder[b[key]]
            : Infinity;
        return direction === "ascending" ? valueA - valueB : valueB - valueA;
      } else if (key === "review") {
        const fitA = Math.round(this.getCompanyScore(a) * 10);
        const fitB = Math.round(this.getCompanyScore(b) * 10);
        return direction === "ascending" ? fitA - fitB : fitB - fitA;
      } else {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      }
    });

    this.setState({
      results: sortedResults,
      sortConfig: {
        key,
        direction,
      },
    });
  };

  getAvatarUrl = (array, file_type, temp_background_url) => {
    let new_arr = array.filter((file) => {
      return file.file_type === file_type;
    });
    if (file_type === "company avatar") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    }
    if (file_type === "company background") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        if (
          temp_background_url !== undefined &&
          temp_background_url !== null &&
          temp_background_url.length > 0
        ) {
          return temp_background_url;
        } else {
          return default_background;
        }
      }
    }
  };

  getCompanyScore(company) {
    var rating = 0;
    var final = 0;
    if (company.reviews && company.reviews.length > 0) {
      for (let i = 0; i < company.reviews.length; i++) {
        rating = rating + company.reviews[i].overallRating;
      }

      var final = rating / company.reviews.length;
    }
    return final.toFixed(1);
  }

  getBackgroundUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_background;
      }
    } else {
      return default_background;
    }
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };
  changeRating = (newRating, name) => {
    this.setState(
      {
        [name]: newRating,
      },
      () => {
        var sumOfAdditional = 0;
        var lengthOfAdditional = 0;
        if (
          (this.props.opportunity_data?.additional_review_criteria || [])
            .length > 0
        ) {
          if (
            this.state.additional_review_criteria &&
            this.state.additional_review_criteria.length > 0
          ) {
            sumOfAdditional = Object.values(
              this.state.additional_review_criteria,
            ).reduce((a, b) => a + b);
            lengthOfAdditional = Object.keys(
              this.state.additional_review_criteria,
            ).length;
          }
          this.setState({
            overallRating:
              (this.state.idea +
                this.state.technology +
                this.state.marketing +
                this.state.team) /
              4,
            pitchOverallRating:
              (this.state.idea +
                this.state.technology +
                this.state.marketing +
                this.state.team +
                sumOfAdditional) /
              (4 + lengthOfAdditional),
          });
        } else {
          sumOfAdditional = 0;
          lengthOfAdditional = 0;
          this.setState({
            overallRating:
              (this.state.idea +
                this.state.technology +
                this.state.marketing +
                this.state.team) /
              4,
            pitchOverallRating:
              (this.state.idea +
                this.state.technology +
                this.state.marketing +
                this.state.team +
                sumOfAdditional) /
              (4 + lengthOfAdditional),
          });
        }
      },
    );
  };
  handleToggle = (e) => {
    e.preventDefault();
    this.setState({
      showOpenReview: !this.state.showOpenReview,
    });
  };
  handleChange = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        if (name === "sortby") {
          this.runFilterSequence();
        }
      },
    );
  };

  updateStatusCard = () => {
    this.setState({ statusChanged: false });
  };

  updateStatus(values) {
    console.log("updateStatus");
    console.log(values);
    this.setState({ updatedStatus: values });
    axios
      .post("/edit_status", {
        applicant_company: values.applicant_company,
        pertains_to_opportunity: values.pertains_to_opportunity,
        application_status: values.application_status,
        changed_by: values.user,
      })
      .then((response) => {
        console.log("check edit status response", response);
        console.log(values.pertains_to_opportunity);
        setTimeout(() => {
          this.setState({
            statusChanged: true,
          });
          this.refreshCompanies();
        }, 150);
        /*axios
          .get(
            `fetch_submitted_companies_for_opportunity?opportunity_id=${values.pertains_to_opportunity}`
          )
          .then((response) => {
            console.log("get list", response);
            if (response.data.status !== "None") {
              const nostatusresult =
                response.data.full_company_list_with_flags.filter(
                  (word) => !word.application_status
                );

              const result = response.data.full_company_list_with_flags.filter(
                (word) => word.application_status == "new"
              );

              const review = response.data.full_company_list_with_flags.filter(
                (word) => word.application_status == "review"
              );

              const first = response.data.full_company_list_with_flags.filter(
                (word) => word.application_status == "firstdiligence"
              );

              const deep = response.data.full_company_list_with_flags.filter(
                (word) => word.application_status == "deepdilligence"
              );
              const offer = response.data.full_company_list_with_flags.filter(
                (word) => word.application_status == "offer"
              );

              this.setState({
                newstatus: result.length,
                nostatusresult: nostatusresult,
                reviewstatus: review.length,
                firstdiligencestatus: first.length,
                deepdelligencestatus: deep.length,
                offerstatus: offer.length,

                companies: response.data.full_company_list_with_flags,
                original_company_list:
                  response.data.full_company_list_with_flags,
                loaded: true,
              });
            } else {
              this.setState({
                loaded: true,
              });
            }
          });*/
      });
  }

  updateCompanyData = (applicantCompanyId, updates) => {
    console.log(`Updating Company ${applicantCompanyId}`);
    console.log("With updates:", updates);

    const updateCompanyList = (companyList) => {
      return companyList.map((company) => {
        if (company.applicant_company === applicantCompanyId) {
          const updatedCompany = {
            ...company,
            ...updates, // Merges the updates into the company object
          };
          console.log("Updated Company:", updatedCompany); // Log the updated company here
          return updatedCompany;
        }
        return company;
      });
    };

    // Update both results and companies
    const updatedResults =
      this.state.results.length > 0
        ? updateCompanyList(this.state.results)
        : this.state.results;

    const updatedCompanies = updateCompanyList(this.state.companies);

    // Update the selected company if it matches the applicantCompanyId
    const updatedSelectedCompany =
      this.state.selected_company.length > 0 &&
      this.state.selected_company[0].applicant_company === applicantCompanyId
        ? updateCompanyList(this.state.selected_company)
        : this.state.selected_company;

    // Set the updated state for results, companies, and selected_company
    this.setState(
      {
        results: updatedResults,
        companies: updatedCompanies,
        selected_company: updatedSelectedCompany,
      },
      () => {
        // Callback after state is updated to confirm the change
        const updatedCompany = this.state.companies.find(
          (company) => company.applicant_company === applicantCompanyId,
        );
        console.log("Updated State:", updatedCompany);
      },
    );
  };

  previewSelectedCompany = (e, company_id, refresh) => {
    e.preventDefault();
    console.log("company_id = " + company_id);
    var index = this.state.original_company_list
      .map(function (o) {
        return o.applicant_company;
      })
      .indexOf(company_id);
    console.log(this.state.original_company_list[index]);
    if (refresh === undefined) {
      this.setState({
        success_msg: "",
      });
    }
    if (
      (this.props.opportunity_data?.additional_review_criteria || []).length > 0
    ) {
      (this.props.opportunity_data?.additional_review_criteria || []).map(
        (criteria) => {
          this.setState((prevState) => ({
            additional_review_criteria: {
              // object that we want to update
              ...prevState.additional_review_criteria, // keep all other key-value pairs
              [criteria]: 5, // update the value of specific key
            },
            totalAdditionalReviewCriteria: {
              ...prevState.totalAdditionalReviewCriteria, // keep all other key-value pairs
              [criteria]: 0,
            },
          }));
        },
      );
    }
    document.body.style.overflow = "hidden";

    this.setState(
      {
        totalOverall: 0,
        totalIdea: 0,
        totalTechnology: 0,
        totalMarketing: 0,
        totalTeam: 0,
        reviews: [],
        current_judge_review: {},
        current_judge_has_reviewed: false,
        idea: 5,
        technology: 5,
        marketing: 5,
        team: 5,
        overallRating: 5,
        pitchOverallRating: 5,
        comment: "",
        feedback: "",
        original_company: this.state.original_company_list[index],
        modifyCredit: this.props.modifyCredit,
        updateCompanyData: this.updateCompanyData,
      },
      () => {
        this.setState({
          selected_company: this.state.companies.filter((obj) => {
            return obj.applicant_company === company_id;
          }),
          showPopUpRate: true,
        });
        axios
          .get(
            `fetch_reviews?opportunity_id=${this.state.url.query.opportunity_id}&applicant_id=${company_id}`,
          )
          .then((response) => {
            this.setState(
              {
                reviews: response.data.reviews_list,
              },
              () => {
                let overallRatingArr = 0;
                let ideaRatingArr = 0;
                let technologyRatingArr = 0;
                let marketingRatingArr = 0;
                let teamRatingArr = 0;
                let additionalReviewCriteriaRatingObj = {};
                if (
                  (
                    this.props.opportunity_data?.additional_review_criteria ||
                    []
                  ).length > 0
                ) {
                  this.props.opportunity_data.additional_review_criteria.map(
                    (criteria) => {
                      additionalReviewCriteriaRatingObj[criteria] = 0;
                    },
                  );
                }
                let b = 0;
                this.state.reviews.map((review, i) => {
                  if (
                    review.pitchOverallRating === undefined ||
                    review.pitchOverallRating === null
                  ) {
                    overallRatingArr += review.overallRating;
                  } else {
                    overallRatingArr += review.pitchOverallRating;
                  }

                  ideaRatingArr += review.idea;
                  technologyRatingArr += review.technology;
                  marketingRatingArr += review.marketing;
                  teamRatingArr += review.team;

                  //calcs each reviews additional criteria contribution
                  if (
                    (
                      this.props.opportunity_data?.additional_review_criteria ||
                      []
                    ).length > 0
                  ) {
                    this.props.opportunity_data.additional_review_criteria.map(
                      (criteria) => {
                        if (review.additional_review_criteria !== undefined) {
                          additionalReviewCriteriaRatingObj[criteria] +=
                            review.additional_review_criteria[criteria];
                        }
                      },
                    );
                  }

                  let y = i + 1;

                  if (review.judge._id === this.props.user._id) {
                    b = 1;
                    this.setState({
                      current_judge_review: review,
                      current_judge_has_reviewed: true,
                      idea: review.idea,
                      idea_comment: review.idea_comment,
                      technology: review.technology,
                      technology_comment: review.technology_comment,
                      marketing: review.marketing,
                      team_comment: review.team_comment,
                      team: review.team,
                      marketing_comment: review.marketing_comment,
                      additional_review_criteria:
                        review.additional_review_criteria,
                      additional_review_criteria_comment:
                        review.additional_review_criteria_comment,
                      overallRating: review.overallRating,
                      pitchOverallRating: review.pitchOverallRating,
                      comment: review.comment,
                      feedback: review.feedback,
                    });
                  }
                  if (review.judge._id === this.props.user._id && b === 0) {
                    this.setState({
                      current_judge_review: {},
                      current_judge_has_reviewed: false,
                      problem: "",
                      solution: "",
                      traction: "",
                      idea: 5,
                      technology: 5,
                      marketing: 5,
                      team: 5,
                      overallRating: 5,
                      pitchOverallRating: 5,
                      comment: "",
                      feedback: "",
                    });
                  }
                  if (y === this.state.reviews.length) {
                    //added this variable to account for additional criteria in total rating calc
                    let total_rating = 0;
                    if (
                      (
                        this.props.opportunity_data
                          ?.additional_review_criteria || []
                      ).length > 0
                    ) {
                      (
                        this.props.opportunity_data
                          ?.additional_review_criteria || []
                      ).map((criteria) => {
                        additionalReviewCriteriaRatingObj[criteria] =
                          additionalReviewCriteriaRatingObj[criteria] /
                          this.state.reviews.length;
                        total_rating +=
                          additionalReviewCriteriaRatingObj[criteria];
                      });
                    }

                    this.setState(
                      {
                        totalOverall:
                          overallRatingArr / this.state.reviews.length,
                        totalIdea: ideaRatingArr / this.state.reviews.length,
                        totalTechnology:
                          technologyRatingArr / this.state.reviews.length,
                        totalMarketing:
                          marketingRatingArr / this.state.reviews.length,
                        totalTeam: teamRatingArr / this.state.reviews.length,
                        totalAdditionalReviewCriteria:
                          additionalReviewCriteriaRatingObj,
                      },
                      () => {
                        total_rating +=
                          this.state.totalIdea +
                          this.state.totalTechnology +
                          this.state.totalMarketing +
                          this.state.totalTeam;
                        // Use optional chaining and logical OR to ensure a number is always available
                        const criteriaLength = (
                          this.props.opportunity_data
                            ?.additional_review_criteria || []
                        ).length;
                        total_rating = total_rating / (4 + criteriaLength);

                        this.setState({
                          totalOverall: total_rating,
                        });
                      },
                    );
                  }

                  if (
                    this.state.selected_company_id !== company_id &&
                    refresh === "yes"
                  ) {
                    axios
                      .get(
                        `fetch_companies_opportunity_applied?opportunity_id=${this.state.url.query.opportunity_id}`,
                      )
                      .then((response) => {
                        console.log("response 1080", response);
                        this.setState(
                          {
                            companies: response.data.full_list,
                            original_company_list: response.data.full_list,
                            selected_company_id: company_id,
                            success_msg: "",
                          },
                          () => {
                            if (this.state.tab === 0) {
                              this.setState({
                                companies: this.state.original_company_list,
                              });
                            }
                            if (this.state.tab === 1) {
                              this.setState(
                                {
                                  companies: this.state.original_company_list,
                                },
                                () => {
                                  this.setState({
                                    companies: this.state.companies.filter(
                                      (company) =>
                                        company.reviews.some(
                                          (review) =>
                                            this.props.user._id ===
                                            review.judge,
                                        ),
                                    ),
                                  });
                                },
                              );
                            }
                            if (this.state.tab === 2) {
                              this.setState(
                                {
                                  companies: this.state.original_company_list,
                                },
                                () => {
                                  this.setState({
                                    companies: this.state.companies.filter(
                                      (company) =>
                                        company.reviews.every(
                                          (review) =>
                                            this.props.user._id !==
                                            review.judge,
                                        ),
                                    ),
                                  });
                                },
                              );
                            }
                          },
                        );
                      });
                  }
                });
              },
            );
          });
      },
    );
  };
  // reviewSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(
  //     "this.state.selected_company[0].applicant_company = " +
  //       this.state.selected_company[0].applicant_company,
  //   );
  //   axios
  //     .post("/create_review", {
  //       applicant_company: this.state.selected_company[0].applicant_company,
  //       opportunity_id: this.state.url.query.opportunity_id,
  //       judge_id: this.props.user._id,
  //       idea: this.state.idea,
  //       idea_comment: this.state.idea_comment,
  //       technology: this.state.technology,
  //       technology_comment: this.state.technology_comment,
  //       marketing: this.state.marketing,
  //       marketing_comment: this.state.marketing_comment,
  //       team: this.state.team,
  //       team_comment: this.state.team_comment,
  //       overallRating: this.state.overallRating,
  //       pitchOverallRating: this.state.pitchOverallRating,
  //       comment: this.state.comment,
  //       feedback: this.state.feedback,
  //       additional_review_criteria: this.state.additional_review_criteria,
  //       additional_review_criteria_comment:
  //         this.state.additional_review_criteria_comment,
  //     })
  //     .then((response) => {
  //       console.log("get review response");
  //       if (response.data.status === "Review Created") {
  //         this.setState(
  //           {
  //             current_judge_review: response.data.result,
  //             current_judge_has_reviewed: true,
  //             showOpenReview: false,
  //             success_msg:
  //               "Your review has been submitted. Feel free to review the next company.",
  //             selected_company: [],
  //           },
  //           () => {
  //             //this.previewSelectedCompany(e,this.state.selected_company[0]._id,"yes")
  //             axios
  //               .get(
  //                 `fetch_companies_opportunity_applied?opportunity_id=${this.state.url.query.opportunity_id}`,
  //               )
  //               .then((response) => {
  //                 this.setState(
  //                   {
  //                     companies: response.data.full_list,
  //                     original_company_list: response.data.full_list,
  //                   },
  //                   () => {
  //                     if (this.state.tab === 0) {
  //                       this.setState({
  //                         companies: this.state.original_company_list,
  //                       });
  //                     }
  //                     if (this.state.tab === 1) {
  //                       this.setState(
  //                         {
  //                           companies: this.state.original_company_list,
  //                         },
  //                         () => {
  //                           this.setState({
  //                             companies: this.state.companies.filter(
  //                               (company) =>
  //                                 company.reviews.some(
  //                                   (review) =>
  //                                     this.props.user._id === review.judge,
  //                                 ),
  //                             ),
  //                           });
  //                         },
  //                       );
  //                     }
  //                     if (this.state.tab === 2) {
  //                       this.setState(
  //                         {
  //                           companies: this.state.original_company_list,
  //                         },
  //                         () => {
  //                           this.setState({
  //                             companies: this.state.companies.filter(
  //                               (company) =>
  //                                 company.reviews.every(
  //                                   (review) =>
  //                                     this.props.user._id !== review.judge,
  //                                 ),
  //                             ),
  //                           });
  //                         },
  //                       );
  //                     }
  //                   },
  //                 );
  //               });
  //           },
  //         );
  //       } else {
  //       }
  //     });
  // };

  reviewSubmit = (e) => {
    e.preventDefault();
    const reviewData = {
      applicant_company: this.state.selected_company[0].applicant_company,
      opportunity_id: this.state.url.query.opportunity_id,
      judge_id: this.props.user._id,
      idea: this.state.idea,
      idea_comment: this.state.idea_comment,
      technology: this.state.technology,
      technology_comment: this.state.technology_comment,
      marketing: this.state.marketing,
      marketing_comment: this.state.marketing_comment,
      team: this.state.team,
      team_comment: this.state.team_comment,
      overallRating: this.state.overallRating,
      pitchOverallRating: this.state.pitchOverallRating,
      comment: this.state.comment,
      feedback: this.state.feedback,
      additional_review_criteria: this.state.additional_review_criteria,
      additional_review_criteria_comment:
        this.state.additional_review_criteria_comment,
    };

    axios
      .post("/create_review", reviewData)
      .then((response) => {
        // Assuming the response contains the created review data
        const newReview = {
          _id: response.data._id, // Assuming the server returns the new review's ID
          ...reviewData,
          judge: this.props.user._id,
          created_on: new Date().toISOString(),
          __v: 0,
        };

        this.setState(
          (prevState) => ({
            companies: prevState.companies.map((company) =>
              company._id === this.state.selected_company[0]._id
                ? { ...company, reviews: [...company.reviews, newReview] }
                : company,
            ),
          }),
          () => {
            console.log("Review added to local state");
            this.handleHide();
          },
        );
      })
      .catch((error) => {
        console.error("Error submitting review", error);
        // Handle any errors here
      });
  };

  editReviewSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/edit_review", {
        applicant_company: this.state.selected_company[0].applicant_company,
        opportunity_id: this.state.url.query.opportunity_id,
        judge_id: this.props.user._id,
        idea: this.state.idea,
        idea_comment: this.state.idea_comment,
        technology: this.state.technology,
        technology_comment: this.state.technology_comment,
        marketing: this.state.marketing,
        marketing_comment: this.state.marketing_comment,
        team: this.state.team,
        team_comment: this.state.team_comment,
        overallRating: this.state.overallRating,
        pitchOverallRating: this.state.pitchOverallRating,
        comment: this.state.comment,
        feedback: this.state.feedback,
        additional_review_criteria: this.state.additional_review_criteria,
        additional_review_criteria_comment:
          this.state.additional_review_criteria_comment,
      })
      .then(this.handleHide);
  };

  handleHide = () => {
    this.setState({
      showPopUpRate: false,
      selected_company: [],
      showOpenReview: false,
    });
    document.body.style.overflow = "auto";
  };
  getFileUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      }
    } else {
      return 0;
    }
  };
  toggleShare = (e) => {
    e.preventDefault();
    this.setState({
      showSharePopup: !this.state.showSharePopup,
    });
  };
  handleSharePopup = (e) => {
    this.setState({
      showSharePopup: false,
    });
  };

  computeExcel = (e) => {
    let excel_data = [];
    for (let i = 0; i < this.state.companies.length; i++) {
      let value = {
        ...this.state.companies[i],
        ...this.state.companies[i].company_owner,
      };
      delete value.company_owner;
      delete value.password;
      delete value.settings;
      delete value.reviews;
      delete value.overallRatingArr;
      delete value.resetPasswordExpires;
      delete value.resetPasswordToken;
      delete value.most_active_index;
      delete value.manual_team_list;
      delete value.lat;
      delete value.long;
      delete value.meta_short_bio;
      delete value.profile_completeness;
      delete value.stealth;
      delete value.prospective_aff_email;
      delete value.prospective_aff_code;
      delete value.referrer_source;
      delete value.__v;
      delete value.temp_background_url;
      delete value.owner;
      delete value.comm_aff;
      delete value.index;
      delete value.company_files;
      delete value.applicant_company;
      delete value.pertains_to_opportunity;
      delete value._id;
      delete value.action_type;
      delete value.changed_by;
      delete value.first_name;
      delete value.last_name;
      delete value.meta_short_bio;

      console.log(value.stage);
      value.stage = this.getStage(value.stage, stage_list);
      console.log(value.stage);

      excel_data.push(value);
      if (excel_data.length === this.state.companies.length) {
        this.setState(
          {
            excel_data: excel_data,
          },
          () => {
            return true;
          },
        );
      }
    }
  };

  handleChangeAdditionalRatingComment = (e) => {
    const name = e.target.name;
    const { additional_review_criteria_comment } = this.state;
    this.setState({
      additional_review_criteria_comment: {
        ...additional_review_criteria_comment,
        [name]: e.target.value,
      },
    });
  };

  handleChangeAdditionalRating = (newRating, name) => {
    this.setState(
      (prevState) => ({
        additional_review_criteria: {
          // object that we want to update
          ...prevState.additional_review_criteria, // keep all other key-value pairs
          [name]: newRating, // update the value of specific key
        },
      }),
      () => {
        let sumOfAdditional = Object.values(
          this.state.additional_review_criteria,
        ).reduce((a, b) => a + b);
        let lengthOfAdditional = Object.keys(
          this.state.additional_review_criteria,
        ).length;
        this.setState({
          overallRating:
            (this.state.idea +
              this.state.technology +
              this.state.marketing +
              this.state.team) /
            4,
          pitchOverallRating:
            (this.state.idea +
              this.state.technology +
              this.state.marketing +
              this.state.team +
              sumOfAdditional) /
            (4 + lengthOfAdditional),
        });
      },
    );
  };

  roundAdditionalCriteria = (criteria) => {
    if (this.state.totalAdditionalReviewCriteria[criteria] !== undefined) {
      let x = this.state.totalAdditionalReviewCriteria[criteria];
      let y = x.toFixed(1);
      return y;
    } else {
      return this.state.totalAdditionalReviewCriteria[criteria];
    }
  };

  flagged_popup = (e, company) => {
    console.log("company 1522", company.company_name);
    this.handleHide();
    e.preventDefault();
    this.setState({
      activeCompany: company,
      showFlagPopup: true,
      popup_header: `Are you sure you would like to delete ${company.company_name}?`,
      popup_body: "Deleted companies will NOT be recoverable.",
    });
  };

  handleFlaggingChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleFlaggingHide = (e) => {
    console.log("abc");
    this.setState({
      showPopUpRate: false,
      showFlagPopup: false,
      showCommunityPopup: false,
    });
  };

  handleDelete = (e, company_id, isStealth = false) => {
    console.log(
      "this.state.url.query.opportunity_id",
      this.state.url.query.opportunity_id,
    );
    e.preventDefault();
    this.setState({
      applicant_rejection_email: {
        ...this.state.applicant_rejection_email,
        email: this.state.activeCompany?.prospective_aff_email,
      },
    });
    axios
      .post("/create_flagged_company_in_opportunity", {
        flagged_company: company_id,
        opportunity: this.state.url.query.opportunity_id,
        flagged_by: this.props.user._id,
      })
      .then((response) => {
        axios
          .post("/delete_flagged_company_from_opportunity", {
            opportunity_id: this.state.url.query.opportunity_id,
            company_id: company_id,
            applicant_rejection_email: this.state.applicant_rejection_email,
            isStealth: isStealth,
          })

          .then((response) => {
            this.setState({
              showFlagPopup: false,
            });
          });
      });

    this.refreshCompanies();
    setTimeout(() => {
      this.refreshCompanies();
    }, 100);
  };

  create_flagged = (e, company_id, isStealth = true) => {
    // e.preventDefault();
    // this.setState({
    //   applicant_rejection_email: {
    //     ...this.state.applicant_rejection_email,
    //     email: this.state.activeCompany?.prospective_aff_email,
    //   },
    // });
    axios
      .post("/create_flagged_company_in_opportunity", {
        flagged_company: company_id,
        opportunity: this.state.url.query.opportunity_id,
        flagged_by: this.props.user._id,
      })
      // .then((response) => {
      //   axios
      //     .post("/delete_flagged_company_from_opportunity", {
      //       opportunity_id: this.state.url.query.opportunity_id,
      //       company_id: company_id,
      //       applicant_rejection_email: this.state.applicant_rejection_email,
      //       isStealth: isStealth,
      //     })

      .then((response) => {
        this.setState({
          showFlagPopup: false,
        });
      });
    this.refreshCompanies();
    setTimeout(() => {
      this.refreshCompanies();
    }, 100);
  };

  handleScore = (company) => {
    const { matchDataFit } = this.state;
    const businessModel = company?.business_model;
    const targetModelCompanies = matchDataFit?.target_model_companies; // Your target models
    const stage = company?.stage;
    const targetStages = matchDataFit?.target_stage_companies; // Your target stages
    const country = company?.country;
    const targetcountry = matchDataFit?.geographical_location;
    const industry = company?.industry;
    const distinction = company?.distinction;
    const targetdistinction = matchDataFit?.target_distinctions;
    const targetindustry = matchDataFit?.target_industries;
    const businessModelMatch = targetModelCompanies?.includes(businessModel)
      ? 20
      : 0;
    const stageMatch = targetStages?.includes(stage) ? 20 : 0;
    const countryMatch = targetcountry?.includes(country) ? 20 : 0;
    let industrymatch = targetindustry?.some((item) => industry?.includes(item))
      ? 20
      : 0;
    let distinctionmatch = targetdistinction?.some((item) =>
      distinction?.includes(item),
    )
      ? 20
      : 0;
    // const industrymatch = targetindustry.includes(industry) ? 20 : 0;
    // console.log("industrymatch 14111", industrymatch)
    // console.log("distinctionmatch 14111", distinctionmatch)
    const totalPercentage =
      businessModelMatch +
      stageMatch +
      countryMatch +
      industrymatch +
      distinctionmatch;
    return totalPercentage;
  };

  handleSortToggle = (column) => {
    this.setState((prevState) => ({
      sortAscendingreviewScore: !prevState.sortAscendingreviewScore,
      sortByColumn: column,
    }));
  };

  handleSortToggleCFA = (column) => {
    this.setState((prevState) => ({
      sortAscendingCFA: !prevState.sortAscendingCFA,
      sortByColumn: column,
    }));
  };

  // sortCompanies = (column) => {
  //   console.log("column column 1520", column)
  //   // Sort the companies based on the Fit score and sorting order
  //   const { companies, sortAscending, sortAscendingCFA,selectedFilters } = this.state;
  //   console.log("selectedFilters 1520", selectedFilters)
  //   const sortedCompanies = [...companies].sort((a, b) => {
  //     const fitA = this.handleScore(a);
  //     const industryA = a.industry; // Assuming the industry is a property of the company object
  //     const industryB = b.industry;
  //     if (column === 'fit') {
  //       const fitA = this.handleScore(a);
  //       const fitB = this.handleScore(b);
  //       return sortAscending ? fitA - fitB : fitB - fitA;
  //     } else if (column === 'CFA') {
  //       const fitA = this.handleScore(a);
  //       const fitB = this.handleScore(b);
  //       return sortAscendingCFA ? fitA - fitB : fitB - fitA;
  //     }

  //     if (selectedFilters.industry) {
  //       // If an industry filter is selected, filter and sort by industry
  //       if (industryA === selectedFilters.industry && industryB !== selectedFilters.industry) {
  //         return -1;
  //       } else if (industryA !== selectedFilters.industry && industryB === selectedFilters.industry) {
  //         return 1;
  //       }
  //     }
  //   });

  //   return sortedCompanies;
  // };

  sortCompanies = () => {
    const { companies, filters } = this.state;

    return companies.filter((company) => {
      const industryMatch =
        !filters.industry ||
        filters?.industry?.some((item) => company?.industry?.includes(item));
      const countryMatch =
        !filters.country ||
        filters?.country?.some((item) => company?.country?.includes(item));
      const bmoMatch =
        !filters.business_model ||
        filters?.business_model?.some((item) =>
          company?.business_model?.includes(item),
        );
      const stageMatch =
        !filters.stage ||
        filters?.stage?.some((item) => item === company?.stage);
      return industryMatch && countryMatch && bmoMatch && stageMatch;
    });
  };

  handlechangeSort = (e) => {
    const value = e.target.value;
    if (value === "CFA") {
      this.handleSortToggleCFA("CFA");
    }
    if (value === "fit") {
      this.handleSortToggle("reviewScore");
    }
    if (value === "a-z") {
      this.setState({});
    }
  };

  resetFilters = () => {
    this.setState({
      filters: {},
      selectedFilters: {
        ...this.state.selectedFilters,
        industry: "",
      },
      selectedFiltersCountry: {
        ...this.state.selectedFiltersCountry,
        country: "",
      },
      selectedFiltersBmo: {
        ...this.state.selectedFiltersBmo,
        business_model: "",
      },
      selectedFiltersStage: {
        ...this.state.selectedFiltersStage,
        stage: "",
      },
      results: [],
      sortConfig: {
        key: null, // column index
        direction: "ascending", // ascending or descending
      },
    });
  };

  handleFilterChange = (e) => {
    let deleteKey = (object, key) => {
      delete object[key];
      return {
        ...object,
      };
    };
    const name = e.target.name;
    this.setState(
      {
        filters:
          e.target.value.length > 0
            ? {
                ...this.state.filters,
                [name]: e.target.value,
              }
            : deleteKey(this.state.filters, name),
      },
      // this.fetchFeed.bind(this, true)
    );

    console.log("this.state.filters 1657", this.state.filters);
  };

  handleSingleDelete = (data, key) => () => {
    let deleteKey = (object, key) => {
      delete object[key];
      return {
        ...object,
      };
    };
    let filtersx = this.state.filters[key];
    let chipToDelete = filtersx.indexOf(data);
    filtersx.splice(chipToDelete, 1);
    this.setState(
      {
        filters:
          filtersx.length > 0
            ? {
                ...this.state.filters,
                [key]: filtersx,
              }
            : deleteKey(this.state.filters, key),
      },
      // this.fetchFeed.bind(this, true)
    );
  };

  handleFilterChangeCountry = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      selectedFiltersCountry: {
        ...prevState.selectedFiltersCountry,
        [name]: value,
      },
    }));
  };

  handleFilterChangeBmodel = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      selectedFiltersBmo: {
        ...prevState.selectedFiltersBmo,
        [name]: value,
      },
    }));
  };

  handleFilterChangeStage = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      selectedFiltersStage: {
        ...prevState.selectedFiltersStage,
        [name]: value,
      },
    }));

    // console.log('selectedFiltersStage Filters:', this.state.selectedFiltersStage);
  };

  render() {
    const { dateData, sortAscendingDate, results, sortConfig } = this.state;

    let subs_that_still_need_reviewing =
      this.state.original_company_list.filter(
        (company) =>
          company.reviews.length <
          this.props.opportunity_data.number_of_reviews,
      );
    let all_subs =
      this.state.tabTop === 0
        ? this.state.original_company_list
        : subs_that_still_need_reviewing;
    let subs_you_reviewed =
      this.state.tabTop === 0
        ? this.state.original_company_list.filter((company) =>
            company.reviews.some(
              (review) => this.props.user._id === review.judge,
            ),
          )
        : subs_that_still_need_reviewing.filter((company) =>
            company.reviews.some(
              (review) => this.props.user._id === review.judge,
            ),
          );
    let subs_not_reviewed =
      this.state.tabTop === 0
        ? this.state.companies.filter((company) =>
            company.reviews.every(
              (review) => this.props.user._id !== review.judge,
            ),
          )
        : subs_that_still_need_reviewing.filter((company) =>
            company.reviews.every(
              (review) => this.props.user._id !== review.judge,
            ),
          );

    const {
      tabsvalue,
      fitScoreValue,
      sortByColumn,
      selectedFilters,
      selectedFiltersCountry,
      selectedFiltersBmo,
      selectedFiltersStage,
      CFA,
      reviewScore,
      Alfabate,
      refrecompany,
      CompanyFileNew,
    } = this.state;
    if (this.state.filters) {
      const sortedCompanies = this.sortCompanies(sortByColumn);
    }
    const sortedCompanies = this.sortCompanies(sortByColumn);

    const companiesToDisplay =
      this.state.results.length > 0 ? this.state.results : sortedCompanies;
    // console.log("sortedCompanies",sortedCompanies)

    console.log("companiesToDisplay", companiesToDisplay);

    const businessTypeMap = {
      B2B: "B2B - Business To Business",
      B2C: "B2C - Business To Customers",
      B2G: "B2G - Business To Government",
      Marketplace: "Marketplace - Consumer To Consumer",
    };

    const displayNames = Object.keys(businessTypeMap);

    return (
      <div>
        <div class="report-flagging-popup">
          {/* {console.log("this.state.activeCompany",this.state.activeCompany?.prospective_aff_email)} */}
          <Report_Company_Flagging
            show={this.state.showFlagPopup}
            handleChange={this.handleFlaggingChange}
            dismiss={this.handleFlaggingHide}
            company_id={this.state.activeCompany.applicant_company}
            create_flagged={this.create_flagged}
            handleDelete={this.handleDelete}
            handleRejectionEmail={this.handleRejectionEmail}
            headerRejection={this.state.applicant_rejection_email.header}
            bodyRejection={this.state.applicant_rejection_email.body}
            {...this.state}
          />
        </div>
        <Modal
          classNames="rounded"
          open={this.state.isOpen}
          onClose={this.closeModal}
          closeOnOverlayClick={false}
        >
          {/* <Modal.Header closeButton> */}

          {/* </Modal.Header> */}
          <div className="flex">
            <div className="h-full w-full">
              <h4 className="ml-5 mt-3">Add Deal</h4>
              <p className="ml-5">
                Add in stealth, or use toggle to invite startup founder to
                provide company updates.
              </p>
              <Add_Company_Form closeModal={this.closeModal} />
            </div>
          </div>
        </Modal>
        <ArgNav
          opportunity_name={this.props.opportunity_name}
          opportunity_id={this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname}
        />

        <React.Fragment>
          {this.state.loaded ? (
            <>
              <Opportunity_Judge_Share
                {...this.state}
                {...this.props}
                handleChange={this.handleChange}
                handleSharePopup={this.handleSharePopup}
              />

              <div className="__content">
                <div className="__pb-page m-2">
                  <div className="deals-page-content p-3">
                    <div className="filter-sidebar">
                      {/* Group data here */}
                      <br />
                      {/*<AppBar
                  position="static"
                  color="default"
                  className="status_bar hide-"
                >
                  <Tabs
                    value={tabsvalue}
                    onChange={this.handleTabsSubmissionChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    // variant="scrollable"
                    // scrollButtons="auto"
                  >
                    <Tab
                      label={
                        <Typography>
                          No Status ({this.state.nostatusresult.length})
                        </Typography>
                      }
                    />
                    <Tab
                      label={
                        <Typography>New ({this.state.newstatus})</Typography>
                      }
                    />
                    <Tab
                      label={
                        <Typography>
                          Reviewing ({this.state.reviewstatus})
                        </Typography>
                      }
                    />
                    <Tab
                      label={
                        <Typography>
                          1st Dilligence ({this.state.firstdiligencestatus})
                        </Typography>
                      }
                    />
                    <Tab
                      label={
                        <Typography>
                          {" "}
                          Deep Dilligence({this.state.deepdelligencestatus})
                        </Typography>
                      }
                    />
                    <Tab
                      label={
                        <Typography>
                          {" "}
                          Offer({this.state.offerstatus})
                        </Typography>
                      }
                    />
                  </Tabs>
                </AppBar>*/}

                      <Modal
                        dialogClassName="my-modal"
                        aria-labelledby="example-custom-modal-styling-title"
                        //aria-labelledby="example-modal-sizes-title-sm"
                        size="sm"
                        open={this.state.isOpenTable}
                        onClose={this.closeTableModal}
                        center
                        closeOnOverlayClick={false}
                      >
                        {/* <Modal.Header>
                    <span
                      style={{
                        textAlign: "center",
                        color: "#5e72e4",
                        fontSize: "30px",
                      }}
                    > */}
                        <h2 className="change-status-heading">
                          Change Status of{" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "#5e72e4",
                              lineHeight: "5vh",
                            }}
                          >
                            {this.state.activeCompany.company_name}
                          </span>
                        </h2>
                        {/* </span> */}
                        {/* </Modal.Header> */}

                        {/* <Modal.Body> */}
                        <div className="change-status-select">
                          {!this.state.statusChanged && (
                            <select
                              value={this.state.application_status}
                              onChange={(e) =>
                                this.handleApplicationStatusChange(e)
                              }
                              style={{
                                // border: "2px solid #29BD00",
                                // borderRadius: "12px",
                                height: "40px",
                                padding: "0px 0px 0px 10px ",
                                backgroundColor: "white",
                                margin: "0px 2px 0px 0px ",
                                fontSize: 14,
                                width: "100%",
                              }}
                            >
                              {options.map((option) => (
                                <option value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          )}
                          {this.state.statusChanged && (
                            <h3 class="text-center">Status Changed</h3>
                          )}
                        </div>
                        {/* </Modal.Body> */}

                        {/* <Modal.Footer> */}
                        <div className="button-change-status">
                          {!this.state.statusChanged && (
                            <MUIButton
                              variant="contained"
                              color="primary"
                              onClick={() => this.changestatus()}
                              size="small"
                              className="button-status"
                            >
                              Submit
                            </MUIButton>
                          )}
                          <MUIButton
                            variant="contained"
                            color="primary"
                            onClick={() => this.closeTableModal()}
                            size="small"
                          >
                            Close
                          </MUIButton>
                        </div>
                        {/* </Modal.Footer> */}
                      </Modal>
                    </div>
                    <div className="deals-column">
                      <div className="__header p-4 text-right">
                        <div className="__title float-left">
                          <h2 className="font-sans">Deals</h2>
                        </div>
                        <div className="float-right">
                          {this.props.opportunity_owner._id ===
                            this.props.user._id && (
                            <CSVLink
                              data={this.state.excel_data}
                              className="__csv-download"
                              onClick={() => {
                                this.computeExcel();
                              }}
                            >
                              <i className="fas fa-file-export __aux_icons"></i>
                            </CSVLink>
                          )}
                          <i
                            className="fas fa-share-alt __aux_icons"
                            onClick={this.toggleShare}
                          ></i>
                        </div>
                        <div className=" __search-field-wrapper float-right">
                          <label className="__search-field">
                            <input
                              value={this.state.query_search}
                              placeholder="Search for..."
                              ref={(input) => (this.search = input)}
                              onChange={this.handleSearchInputChange}
                            />
                          </label>
                        </div>

                        {/* Removed on 2022-08/30 and added the use of the status bar
              <div className="__application-room-padding">
                
                {this.props.opportunity_data !== undefined &&
                  this.props.opportunity_data !== null &&
                  this.props.opportunity_data.limit_number_of_reviews && (
                  
                
                <div className="__submissionroom-tabs">
                  <div
                    onClick={() => this.handleTabChange(0)}
                    className={this.state.tab === 0 ? "active" : ""}
                  >
                    All ({all_subs.length}){" "}
                    <i className="material-icons">remove_red_eye</i>
                  </div>
                  <div
                    onClick={() => this.handleTabChange(1)}
                    className={this.state.tab === 1 ? "active" : ""}
                  >
                    Reviewed ({subs_you_reviewed.length}){" "}
                    <i className="material-icons">star</i>
                  </div>

                  <div
                    onClick={() => this.handleTabChange(2)}
                    className={this.state.tab === 2 ? "active" : ""}
                  >
                    To Review (
                    {subs_not_reviewed.length}){" "}
                    <i className="material-icons">star_border</i>
                  </div>
                </div> 
              </div>*/}
                        <Button
                          className="add-company-button btn btn-md float-right"
                          variant="primary"
                          onClick={this.openModal}
                        >
                          &#43; Add Deal
                        </Button>
                        <Button
                          className="add-company-button btn btn-md float-right"
                          variant="primary"
                          onClick={() => {
                            window.location.href = `applicant_room_flagged?opportunity_id=${this.state.url.query.opportunity_id}`;
                          }}
                        >
                          Archived Deals
                        </Button>
                      </div>
                      <div className="sort-by-deal-page">
                        <div
                          class="btn-group __table-card-select border-dark table-kanban-toggle"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-grey rounded border border-dark bg-black text-white"
                            className={`btn btn-sm rounded btn-outline-grey${
                              this.state.tableCard === "table" ? " active" : ""
                            }`}
                            onClick={this.handleTableChange("table")}
                          >
                            Table
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-grey rounded border border-dark"
                            className={`btn rounded btn-sm btn-outline-grey${
                              this.state.tableCard === "card" ? " active" : ""
                            }`}
                            onClick={this.handleTableChange("card")}
                          >
                            Kanban
                          </button>
                        </div>
                      </div>
                      <div className="filter-deal-page flex ml-3">
                        <div className="FilterUbaidForm">
                          <FormControl>
                            <InputLabel htmlFor="select-multiple-chip">
                              Industry
                            </InputLabel>
                            <Select
                              multiple
                              value={this.state.filters.industry || []}
                              onChange={this.handleFilterChange}
                              input={<Input id="select-multiple-chip" />}
                              name="industry"
                              renderValue={(selected) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {selected.map((value) => (
                                    <Chip
                                      key={value}
                                      label={value}
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                      onDelete={this.handleSingleDelete(
                                        value,
                                        "industry",
                                      )}
                                    />
                                  ))}
                                </div>
                              )}
                              MenuProps={MenuProps}
                            >
                              {industry_list.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <Checkbox
                                    checked={
                                      this.state.filters.industry !== undefined
                                        ? this.state.filters.industry.indexOf(
                                            name,
                                          ) > -1
                                        : ""
                                    }
                                  />
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="FilterUbaidForm">
                          <FormControl>
                            <InputLabel htmlFor="select-multiple-chip">
                              Country
                            </InputLabel>
                            <Select
                              multiple
                              value={this.state.filters.country || []}
                              onChange={this.handleFilterChange}
                              input={<Input id="select-multiple-chip" />}
                              name="country"
                              renderValue={(selected) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {selected.map((value) => (
                                    <Chip
                                      key={value}
                                      label={value}
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                      onDelete={this.handleSingleDelete(
                                        value,
                                        "country",
                                      )}
                                    />
                                  ))}
                                </div>
                              )}
                              MenuProps={MenuProps}
                            >
                              {country_list.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <Checkbox
                                    checked={
                                      this.state.filters.country !== undefined
                                        ? this.state.filters.country.indexOf(
                                            name,
                                          ) > -1
                                        : ""
                                    }
                                  />
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="FilterUbaidForm">
                          <FormControl>
                            <InputLabel htmlFor="select-multiple-chip">
                              Model
                            </InputLabel>
                            <Select
                              multiple
                              value={this.state.filters.business_model || []}
                              onChange={this.handleFilterChange}
                              input={<Input id="select-multiple-chip" />}
                              name="business_model"
                              renderValue={(selected) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {selected.map((value) => (
                                    <Chip
                                      key={value}
                                      label={Object.keys(businessTypeMap).find(
                                        (key) => businessTypeMap[key] === value,
                                      )} // Display the simplified name
                                      onDelete={() =>
                                        this.handleSingleDelete(
                                          value,
                                          "business_model",
                                        )
                                      }
                                    />
                                  ))}
                                </div>
                              )}
                              MenuProps={MenuProps}
                              fullWidth
                            >
                              {displayNames.map((name) => (
                                <MenuItem
                                  key={name}
                                  value={businessTypeMap[name]}
                                >
                                  <Checkbox
                                    checked={
                                      this.state.filters.business_model !==
                                      undefined
                                        ? this.state.filters.business_model.indexOf(
                                            businessTypeMap[name],
                                          ) > -1
                                        : false
                                    }
                                  />
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="FilterUbaidForm">
                          <FormControl>
                            <InputLabel htmlFor="select-multiple-chip">
                              Stage
                            </InputLabel>
                            <Select
                              multiple
                              value={this.state.filters.stage || []}
                              onChange={this.handleFilterChange}
                              input={<Input id="select-multiple-chip" />}
                              name="stage"
                              renderValue={(selected) => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {selected.map((value) => (
                                    <Chip
                                      key={value}
                                      label={value}
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                      onDelete={this.handleSingleDelete(
                                        value,
                                        "stage",
                                      )}
                                    />
                                  ))}
                                </div>
                              )}
                              MenuProps={MenuProps}
                            >
                              {stage_list.map((name) => (
                                <MenuItem key={name.value} value={name.value}>
                                  <Checkbox
                                    checked={
                                      this.state.filters.stage !== undefined
                                        ? this.state.filters.stage.indexOf(
                                            name.value,
                                          ) > -1
                                        : ""
                                    }
                                  />
                                  <ListItemText primary={name.text} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="FilterUbaidBTN m-h">
                          <Button
                            variant="contained"
                            color="secondary"
                            // className={classes.button + " FilterUbaidBTNIn"}
                            onClick={this.resetFilters}
                          >
                            Reset
                          </Button>
                        </div>
                        {/*<div className="table-sort-filter">*/}
                        {/*  <FormControl variant="outlined">*/}
                        {/*    <InputLabel htmlFor="outlined-sortby-native">*/}
                        {/*      Sort By:*/}
                        {/*    </InputLabel>*/}
                        {/*    <Select*/}
                        {/*      className="UbaidSortSelect"*/}
                        {/*      native*/}
                        {/*      onChange={this.handlechangeSort}*/}
                        {/*      input={*/}
                        {/*        <OutlinedInput*/}
                        {/*          name="sortby"*/}
                        {/*          id="outlined-sortby-native-simple"*/}
                        {/*        />*/}
                        {/*      }*/}
                        {/*    >*/}
                        {/*      <option value="" />*/}
                        {/*      /!* <option value={"date_added"}>*/}
                        {/*        Date added*/}
                        {/*      </option>*/}
                        {/*      <option value={"date_updated"}>*/}
                        {/*        Date updated*/}
                        {/*      </option>*/}
                        {/*      <option value={"traction"}>*/}
                        {/*        Traction*/}
                        {/*      </option> *!/*/}
                        {/*      <option value={"CFA"}>Thesis Fit</option>*/}
                        {/*      <option value={"reviewScore"}>*/}
                        {/*        Team Reviews*/}
                        {/*      </option>*/}
                        {/*      /!* <option value={"a-z"}>A to Z</option> *!/*/}
                        {/*    </Select>*/}
                        {/*  </FormControl>*/}
                        {/*</div>*/}
                      </div>
                      {this.state.tableCard === "card" && (
                        <div className="card-deal-page-main-wraper">
                          <div
                            class="btn-group btn-group-md statuses-large"
                            role="group"
                            aria-label="Basic example"
                          >
                            {/* <button type="button" class="btn btn-grey" onClick={this.handleTabsSubmissionChangeNew(0)}>No Status ({this.state.nostatusresult.length})</button> */}
                            <button
                              type="button"
                              className="btn btn-grey"
                              onClick={this.handleTabsSubmissionChangeNew(1)}
                            >
                              New ({this.state.newstatus})
                            </button>
                            <button
                              type="button"
                              className="btn btn-grey"
                              onClick={this.handleTabsSubmissionChangeNew(2)}
                            >
                              Reviewing ({this.state.reviewstatus})
                            </button>
                            <button
                              type="button"
                              className="btn btn-grey"
                              onClick={this.handleTabsSubmissionChangeNew(3)}
                            >
                              1st Diligence ({this.state.firstdiligencestatus})
                            </button>
                            <button
                              type="button"
                              className="btn btn-grey"
                              onClick={this.handleTabsSubmissionChangeNew(4)}
                            >
                              Deep Diligence ({this.state.deepdelligencestatus})
                            </button>
                            <button
                              type="button"
                              className="btn btn-grey"
                              onClick={this.handleTabsSubmissionChangeNew(5)}
                            >
                              Offer ({this.state.offerstatus})
                            </button>
                            <button
                              type="button"
                              className="btn btn-grey"
                              onClick={this.handleTabsSubmissionChangeNew(6)}
                            >
                              Portco ({this.state.portcostatus})
                            </button>
                          </div>

                          <div
                            class="btn-group btn-group-md statuses-small"
                            role="group"
                            aria-label="Button group with nested dropdown"
                          >
                            {/* <button type="button" class="btn btn-grey" onClick={this.handleTabsSubmissionChangeNew(0)}>No Status</button> */}
                            <button
                              type="button"
                              class="btn btn-grey"
                              onClick={this.handleTabsSubmissionChangeNew(1)}
                            >
                              New
                            </button>
                            <button
                              type="button"
                              class="btn btn-grey"
                              onClick={this.handleTabsSubmissionChangeNew(2)}
                            >
                              1st
                            </button>
                            <button
                              type="button"
                              class="btn btn-grey"
                              onClick={this.handleTabsSubmissionChangeNew(3)}
                            >
                              2nd
                            </button>
                            <button
                              type="button"
                              class="btn btn-grey"
                              onClick={this.handleTabsSubmissionChangeNew(4)}
                            >
                              3rd
                            </button>
                            <button
                              type="button"
                              class="btn btn-grey"
                              onClick={this.handleTabsSubmissionChangeNew(5)}
                            >
                              Offer
                            </button>

                            <div class="btn-group btn-group-md" role="group">
                              <button
                                id="btnGroupDrop1"
                                type="button"
                                class="btn btn-grey dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                &nbsp;
                              </button>
                              <div
                                class="dropdown-menu"
                                aria-labelledby="btnGroupDrop1"
                              >
                                <button
                                  class="dropdown-item"
                                  onClick={this.handleTabsSubmissionChangeNew(
                                    2,
                                  )}
                                >
                                  Reviewing
                                </button>
                                <button
                                  class="dropdown-item"
                                  onClick={this.handleTabsSubmissionChangeNew(
                                    3,
                                  )}
                                >
                                  1st Dilligence
                                </button>
                                <button
                                  class="dropdown-item"
                                  onClick={this.handleTabsSubmissionChangeNew(
                                    4,
                                  )}
                                >
                                  Deep Dilligence
                                </button>
                                <button
                                  class="dropdown-item"
                                  onClick={this.handleTabsSubmissionChangeNew(
                                    5,
                                  )}
                                >
                                  Offer
                                </button>
                              </div>
                            </div>
                          </div>

                          {tabsvalue === 0 && (
                            <TabContainer class="__cardholder">
                              <div className="__application-room-profile-card-grid">
                                {this.state.nostatusresult.map(
                                  (company, index) => (
                                    <Profile_Card
                                      getStage={this.getStage}
                                      updateStatus={this.updateStatus}
                                      previewSelectedCompany={
                                        this.previewSelectedCompany
                                      }
                                      getAvatarUrl={this.getAvatarUrl}
                                      getBackgroundUrl={this.getBackgroundUrl}
                                      handleChange={this.handleChange}
                                      opportunity_data={
                                        this.props.opportunity_data
                                      }
                                      getFileUrl={this.getFileUrl}
                                      company_owner={company.owner}
                                      company_score={this.getCompanyScore(
                                        company,
                                      )}
                                      ai_score={this.handleScore(company)}
                                      {...company}
                                      user={this.props.user}
                                      companies_owned={
                                        this.props.companies_owned
                                      }
                                      applicant_company={
                                        company.applicant_company
                                      }
                                      index={index}
                                      statusChanged={this.state.statusChanged}
                                      updateStatusCard={this.updateStatusCard}
                                    />
                                  ),
                                )}
                              </div>
                            </TabContainer>
                          )}

                          {tabsvalue === 1 && (
                            <TabContainer>
                              <div className="__application-room-profile-card-grid">
                                {companiesToDisplay
                                  .filter(
                                    (company) =>
                                      company.application_status === "new",
                                  )
                                  .map((company, index) => (
                                    <Profile_Card
                                      getStage={this.getStage}
                                      updateStatus={this.updateStatus}
                                      previewSelectedCompany={
                                        this.previewSelectedCompany
                                      }
                                      getAvatarUrl={this.getAvatarUrl}
                                      getBackgroundUrl={this.getBackgroundUrl}
                                      handleChange={this.handleChange}
                                      opportunity_data={
                                        this.props.opportunity_data
                                      }
                                      getFileUrl={this.getFileUrl}
                                      company_owner={company.owner}
                                      company_score={this.getCompanyScore(
                                        company,
                                      )}
                                      ai_score={this.handleScore(company)}
                                      {...company}
                                      user={this.props.user}
                                      companies_owned={
                                        this.props.companies_owned
                                      }
                                      applicant_company={
                                        company.applicant_company
                                      }
                                      index={index}
                                      statusChanged={this.state.statusChanged}
                                      updateStatusCard={this.updateStatusCard}
                                    />
                                  ))}
                              </div>
                            </TabContainer>
                          )}
                          {tabsvalue === 2 && (
                            <TabContainer>
                              <div className="__application-room-profile-card-grid">
                                {companiesToDisplay
                                  .filter(
                                    (company) =>
                                      company.application_status === "review",
                                  )
                                  .map((company, index) => (
                                    <Profile_Card
                                      onClick={this.updatestatus}
                                      getStage={this.getStage}
                                      updateStatus={this.updateStatus}
                                      previewSelectedCompany={
                                        this.previewSelectedCompany
                                      }
                                      getAvatarUrl={this.getAvatarUrl}
                                      getBackgroundUrl={this.getBackgroundUrl}
                                      handleChange={this.handleChange}
                                      opportunity_data={
                                        this.props.opportunity_data
                                      }
                                      getFileUrl={this.getFileUrl}
                                      company_owner={company.owner}
                                      company_score={this.getCompanyScore(
                                        company,
                                      )}
                                      ai_score={this.handleScore(company)}
                                      {...company}
                                      user={this.props.user}
                                      companies_owned={
                                        this.props.companies_owned
                                      }
                                      applicant_company={
                                        company.applicant_company
                                      }
                                      index={index}
                                      statusChanged={this.state.statusChanged}
                                      updateStatusCard={this.updateStatusCard}
                                    />
                                  ))}
                              </div>
                            </TabContainer>
                          )}

                          {tabsvalue === 3 && (
                            <TabContainer>
                              <div className="__application-room-profile-card-grid">
                                {companiesToDisplay
                                  .filter(
                                    (company) =>
                                      company.application_status ===
                                      "firstdiligence",
                                  )
                                  .map((company, index) => (
                                    <Profile_Card
                                      getStage={this.getStage}
                                      updateStatus={this.updateStatus}
                                      previewSelectedCompany={
                                        this.previewSelectedCompany
                                      }
                                      getAvatarUrl={this.getAvatarUrl}
                                      getBackgroundUrl={this.getBackgroundUrl}
                                      handleChange={this.handleChange}
                                      opportunity_data={
                                        this.props.opportunity_data
                                      }
                                      getFileUrl={this.getFileUrl}
                                      company_owner={company.owner}
                                      company_score={this.getCompanyScore(
                                        company,
                                      )}
                                      ai_score={this.handleScore(company)}
                                      {...company}
                                      user={this.props.user}
                                      companies_owned={
                                        this.props.companies_owned
                                      }
                                      applicant_company={
                                        company.applicant_company
                                      }
                                      index={index}
                                      statusChanged={this.state.statusChanged}
                                      updateStatusCard={this.updateStatusCard}
                                    />
                                  ))}
                              </div>
                            </TabContainer>
                          )}
                          {tabsvalue === 4 && (
                            <TabContainer>
                              <div className="__application-room-profile-card-grid">
                                {companiesToDisplay
                                  .filter(
                                    (company) =>
                                      company.application_status ===
                                      "deepdilligence",
                                  )
                                  .map((company, index) => (
                                    <Profile_Card
                                      updateStatus={this.updateStatus}
                                      getStage={this.getStage}
                                      previewSelectedCompany={
                                        this.previewSelectedCompany
                                      }
                                      getAvatarUrl={this.getAvatarUrl}
                                      getBackgroundUrl={this.getBackgroundUrl}
                                      handleChange={this.handleChange}
                                      opportunity_data={
                                        this.props.opportunity_data
                                      }
                                      getFileUrl={this.getFileUrl}
                                      company_owner={company.owner}
                                      company_score={this.getCompanyScore(
                                        company,
                                      )}
                                      ai_score={this.handleScore(company)}
                                      {...company}
                                      user={this.props.user}
                                      companies_owned={
                                        this.props.companies_owned
                                      }
                                      applicant_company={
                                        company.applicant_company
                                      }
                                      index={index}
                                      statusChanged={this.state.statusChanged}
                                      updateStatusCard={this.updateStatusCard}
                                    />
                                  ))}
                              </div>
                            </TabContainer>
                          )}
                          {tabsvalue === 5 && (
                            <TabContainer>
                              <div className="__application-room-profile-card-grid">
                                {companiesToDisplay
                                  .filter(
                                    (company) =>
                                      company.application_status === "offer",
                                  )
                                  .map((company, index) => (
                                    <Profile_Card
                                      getStage={this.getStage}
                                      updateStatus={this.updateStatus}
                                      previewSelectedCompany={
                                        this.previewSelectedCompany
                                      }
                                      getAvatarUrl={this.getAvatarUrl}
                                      getBackgroundUrl={this.getBackgroundUrl}
                                      handleChange={this.handleChange}
                                      opportunity_data={
                                        this.props.opportunity_data
                                      }
                                      getFileUrl={this.getFileUrl}
                                      company_owner={company.owner}
                                      company_score={this.getCompanyScore(
                                        company,
                                      )}
                                      ai_score={this.handleScore(company)}
                                      {...company}
                                      user={this.props.user}
                                      companies_owned={
                                        this.props.companies_owned
                                      }
                                      applicant_company={
                                        company.applicant_company
                                      }
                                      index={index}
                                      statusChanged={this.state.statusChanged}
                                      updateStatusCard={this.updateStatusCard}
                                    />
                                  ))}
                              </div>
                            </TabContainer>
                          )}
                          {tabsvalue === 6 && (
                            <TabContainer>
                              <div className="__application-room-profile-card-grid">
                                {companiesToDisplay
                                  .filter(
                                    (company) =>
                                      company.application_status === "portco",
                                  )
                                  .map((company, index) => (
                                    <Profile_Card
                                      getStage={this.getStage}
                                      updateStatus={this.updateStatus}
                                      previewSelectedCompany={
                                        this.previewSelectedCompany
                                      }
                                      getAvatarUrl={this.getAvatarUrl}
                                      getBackgroundUrl={this.getBackgroundUrl}
                                      handleChange={this.handleChange}
                                      opportunity_data={
                                        this.props.opportunity_data
                                      }
                                      getFileUrl={this.getFileUrl}
                                      company_owner={company.owner}
                                      company_score={this.getCompanyScore(
                                        company,
                                      )}
                                      ai_score={this.handleScore(company)}
                                      {...company}
                                      user={this.props.user}
                                      companies_owned={
                                        this.props.companies_owned
                                      }
                                      applicant_company={
                                        company.applicant_company
                                      }
                                      index={index}
                                      statusChanged={this.state.statusChanged}
                                      updateStatusCard={this.updateStatusCard}
                                    />
                                  ))}
                              </div>
                            </TabContainer>
                          )}
                        </div>
                      )}
                      {this.state.tableCard === "table" && (
                        <div className="filter-width-main-wraper bg-white rounded">
                          <div className="side-filter-main-wraper"></div>
                          <div class="card table-card">
                            <div class="table-responsive">
                              <table class="table align-items-center mb-0">
                                <thead>
                                  <tr class="__table-header w-6 text-xs text-gray-100">
                                    {/* <th class="version2.0-10 text-uppercase text-secondary font-weight-bolder opacity-7 px-0 __select-column text-center">Select</th> */}
                                    <th class="version2.0-10 text-uppercase text-secondary font-weight-bolder opacity-7 px-0 __company-name-column">
                                      Deal(s)
                                    </th>
                                    <th class="version2.0-10 text-uppercase text-secondary font-weight-bolder opacity-7 __company-descrip-column">
                                      Description
                                    </th>
                                    <th
                                      onClick={() => this.handleSort("fit")}
                                      className="text-uppercase text-secondary font-weight-bolder opacity-7 py-0 px-0 __score-column"
                                    >
                                      <span className="gradient-text">
                                        AI Score
                                      </span>
                                      {sortConfig.key === "fit" ? (
                                        sortConfig.direction === "ascending" ? (
                                          <span className="upIcon-sort">
                                            <IconButton
                                              aria-label="sort ascending"
                                              size="medium"
                                            >
                                              <ArrowUpward fontSize="small" />
                                            </IconButton>
                                          </span>
                                        ) : (
                                          <span className="downIcon-sort">
                                            <IconButton
                                              aria-label="sort descending"
                                              size="medium"
                                            >
                                              <ArrowDownward fontSize="small" />
                                            </IconButton>
                                          </span>
                                        )
                                      ) : (
                                        <span className="upIcon-sort">
                                          <IconButton
                                            aria-label="sort ascending"
                                            size="medium"
                                          >
                                            <ArrowUpward fontSize="small" />
                                          </IconButton>
                                        </span>
                                      )}
                                    </th>
                                    {/* <th
                                onClick={() => this.handleSortToggle("fit")}
                                class="text-uppercase text-secondary font-weight-bolder opacity-7 py-1 px-0 __score-column">
                                THESIS FIT
                                {this.state.sortAscending ?
                                  <span className="upIcon-sort"><IconButton aria-label="delete" size="medium">
                                    <ArrowUpward fontSize="small" />
                                  </IconButton></span>
                                  :
                                  <span className="DownIcon-sort"><IconButton aria-label="delete" size="medium">
                                    <ArrowDownward fontSize="small" />
                                  </IconButton></span>
                                }
                              </th> */}
                                    <th
                                      onClick={() => this.handleSort("review")}
                                      className="text-uppercase text-secondary font-weight-bolder opacity-7 py-0 px-0 __score-column"
                                    >
                                      <span className="gradient-text">
                                        Team Reviews
                                      </span>
                                      {sortConfig.key === "review" ? (
                                        sortConfig.direction === "ascending" ? (
                                          <span className="upIcon-sort">
                                            <IconButton
                                              aria-label="sort ascending"
                                              size="medium"
                                            >
                                              <ArrowUpward fontSize="small" />
                                            </IconButton>
                                          </span>
                                        ) : (
                                          <span className="downIcon-sort">
                                            <IconButton
                                              aria-label="sort descending"
                                              size="medium"
                                            >
                                              <ArrowDownward fontSize="small" />
                                            </IconButton>
                                          </span>
                                        )
                                      ) : (
                                        <span className="upIcon-sort">
                                          <IconButton
                                            aria-label="sort ascending"
                                            size="medium"
                                          >
                                            <ArrowUpward fontSize="small" />
                                          </IconButton>
                                        </span>
                                      )}
                                    </th>

                                    {/* <th class="text-uppercase text-secondary font-weight-bolder opacity-7 py-1 __model-column">Model</th> */}
                                    {/* <th class="text-uppercase text-secondary font-weight-bolder opacity-7 py-1 __tags-column">Country</th> */}
                                    <th class="text-uppercase text-secondary font-weight-bolder opacity-7 py-1 __tags-column">
                                      Industry
                                    </th>

                                    <th
                                      onClick={() =>
                                        this.handleSort("application_status")
                                      }
                                      className="text-uppercase text-secondary font-weight-bolder opacity-7 py-1 __status-column"
                                    >
                                      Deal Status
                                      {sortConfig.key ===
                                      "application_status" ? (
                                        sortConfig.direction === "ascending" ? (
                                          <span className="upIcon-sort">
                                            <IconButton
                                              aria-label="sort ascending"
                                              size="medium"
                                            >
                                              <ArrowUpward fontSize="small" />
                                            </IconButton>
                                          </span>
                                        ) : (
                                          <span className="downIcon-sort">
                                            <IconButton
                                              aria-label="sort descending"
                                              size="medium"
                                            >
                                              <ArrowDownward fontSize="small" />
                                            </IconButton>
                                          </span>
                                        )
                                      ) : (
                                        <span className="upIcon-sort">
                                          <IconButton
                                            aria-label="sort ascending"
                                            size="medium"
                                          >
                                            <ArrowUpward fontSize="small" />
                                          </IconButton>
                                        </span>
                                      )}
                                    </th>
                                    <th
                                      onClick={() =>
                                        this.handleSort("created_on")
                                      }
                                      className="text-uppercase text-secondary font-weight-bolder opacity-7 py-1 __addedOn-column"
                                    >
                                      Added
                                      {sortConfig.key === "created_on" ? (
                                        sortConfig.direction === "ascending" ? (
                                          <span className="upIcon-sort">
                                            <IconButton
                                              aria-label="sort ascending"
                                              size="medium"
                                            >
                                              <ArrowUpward fontSize="small" />
                                            </IconButton>
                                          </span>
                                        ) : (
                                          <span className="downIcon-sort">
                                            <IconButton
                                              aria-label="sort descending"
                                              size="medium"
                                            >
                                              <ArrowDownward fontSize="small" />
                                            </IconButton>
                                          </span>
                                        )
                                      ) : (
                                        <IconButton
                                          aria-label="sort ascending"
                                          size="medium"
                                        >
                                          <ArrowUpward fontSize="small" />
                                        </IconButton>
                                      )}
                                    </th>
                                    <th className="text-uppercase text-secondary font-weight-bolder opacity-7 py-1 __actions-column">
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {companiesToDisplay.map((company, index) => (
                                    <tr className="_table_row">
                                      {/* <td className="__select-column max-w-4"><input type="checkbox" className="shadow-none"></input></td> */}
                                      <td
                                        class="__company-name-column px-0"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <div
                                          onClick={(event) =>
                                            this.previewSelectedCompany(
                                              event,
                                              company.applicant_company,
                                            )
                                          }
                                          class="d-flex px-0"
                                        >
                                          <div>
                                            <img
                                              className="avatar-sm rounded me-2"
                                              src={this.getAvatarUrl(
                                                company.company_files,
                                                "company avatar",
                                                default_background,
                                              )}
                                            />
                                          </div>
                                          <div class="my-auto">
                                            <h3 class="mb-0 text-lg">
                                              <MaxCharParagraph
                                                text={company.company_name}
                                                maxChars={30}
                                              />
                                            </h3>
                                          </div>
                                        </div>
                                      </td>
                                      <td class="__company-descrip-column">
                                        <div class="d-flex px-2 ">
                                          <div class="my-auto ">
                                            <p class="mb-0">
                                              <MaxCharParagraph
                                                text={company?.short_bio || ""}
                                                maxChars={140}
                                              />
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="__score-column text-lg align-middle">
                                        <div className="gradient-text">
                                          <span>
                                            {this.handleScore(company)}%
                                          </span>
                                        </div>
                                      </td>
                                      <td className="__score-column text-lg align-middle">
                                        <div className="gradient-text text-center">
                                          <span>
                                            {Math.round(
                                              this.getCompanyScore(company) *
                                                10,
                                            )}{" "}
                                          </span>
                                        </div>
                                      </td>
                                      {/* <td class="__score-column text-lg">
                                <div class="progress-circle">
                                    <span class="number">{this.handleScore(company)}%</span>
                                    <div class="left-half-clipper">
                                      <div class="first50-bar"></div>
                                      <div class="value-bar"></div>
                                    </div>
                                </div>
                                </td> */}
                                      {/* <td class="__score-column text-lg">
                                <ProgressTrack
                                    title={this.getCompanyScore(company)}
                                    color="red"
                                    width={this.getCompanyScore(company) * 10 + "%"}
                                />

                                </td> */}

                                      {/* <td class="__model-column">
                                  <div className="exploreCardTags">
                                  <Chip
                                        variant="outlined"
                                        className={classnames(
                                          "exploreTag",
                                          "blueHl"
                                        )}
                                        color={
                                          "primary"
                                        }
                                        size="small"
                                        label={company.business_model}
                                      />
                                  </div>
                                </td> */}
                                      {/* <td class="__tags-column">
                                  <div className="exploreCardTags">
                                    <div className="allTags">
                                      <Chip
                                        variant="outlined"
                                        className={classnames(
                                          "exploreTag",
                                          ""
                                        )}
                                        color={
                                          "default"
                                        }
                                        size="small"
                                        label={company.country}
                                      />
                                      {company.industry.map((val) => (
                                        <Chip
                                          variant="outlined"
                                          className={classnames(
                                            "exploreTag",
                                            "greenHl"
                                          )}
                                          color={
                                            "primary"
                                          }
                                          size="small"
                                          label={val}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                </td> */}
                                      <td className="__tags-column">
                                        <div className="name">
                                          <div className="allTags">
                                            {company.industry
                                              .slice(0, 4)
                                              .map((val, index) => (
                                                <Chip
                                                  key={index}
                                                  variant="outlined"
                                                  className={classnames(
                                                    "exploreTag",
                                                    "greenHl",
                                                  )}
                                                  color="primary"
                                                  size="small"
                                                  label={val}
                                                />
                                              ))}
                                            {company.industry.length > 4 && (
                                              <Chip
                                                variant="outlined"
                                                className={classnames(
                                                  "exploreTag",
                                                  "greenHl",
                                                )}
                                                color="primary"
                                                size="small"
                                                label="..."
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="align-middle __status-column">
                                        <div className="__score-in-status">
                                          <ProgressTrack
                                            title={this.getCompanyScore(
                                              company,
                                            )}
                                            color="#3f51b5"
                                            width={
                                              this.getCompanyScore(company) *
                                                10 +
                                              "%"
                                            }
                                          />
                                        </div>
                                        <div>
                                          {!company.application_status ? (
                                            <div
                                              variant="contained"
                                              className="__status-dropdown"
                                              onClick={(event) =>
                                                this.openTableModal(company)
                                              }
                                              size="small"
                                            >
                                              <span>No Status</span>
                                            </div>
                                          ) : null}
                                          {company.application_status ===
                                            "new" && (
                                            <div
                                              variant="contained"
                                              className="__status-dropdown text-gray"
                                              onClick={(event) =>
                                                this.openTableModal(company)
                                              }
                                              size="small"
                                            >
                                              <span>New</span>
                                            </div>
                                          )}
                                          {company.application_status ===
                                            "review" && (
                                            <div
                                              variant="contained"
                                              className="__status-dropdown text-pink"
                                              onClick={(event) =>
                                                this.openTableModal(company)
                                              }
                                              size="small"
                                            >
                                              <span>Review</span>
                                            </div>
                                          )}
                                          {company.application_status ===
                                            "firstdiligence" && (
                                            <div
                                              variant="contained"
                                              className="__status-dropdown text-orange"
                                              onClick={(event) =>
                                                this.openTableModal(company)
                                              }
                                              size="small"
                                            >
                                              <span>First Diligence</span>
                                            </div>
                                          )}
                                          {company.application_status ===
                                            "deepdilligence" && (
                                            <div
                                              variant="contained"
                                              className="__status-dropdown text-blue"
                                              onClick={(event) =>
                                                this.openTableModal(company)
                                              }
                                              size="small"
                                            >
                                              <span>Deep Diligence</span>
                                            </div>
                                          )}
                                          {company.application_status ===
                                            "offer" && (
                                            <div
                                              variant="contained"
                                              className="__status-dropdown text-gold"
                                              onClick={(event) =>
                                                this.openTableModal(company)
                                              }
                                              size="small"
                                            >
                                              <span>Offer</span>
                                            </div>
                                          )}
                                          {company.application_status ===
                                            "portco" && (
                                            <div
                                              variant="contained"
                                              className="__status-dropdown text-gold"
                                              onClick={(event) =>
                                                this.openTableModal(company)
                                              }
                                              size="small"
                                            >
                                              <span>Portco</span>
                                            </div>
                                          )}
                                          <div className=""></div>
                                        </div>
                                        <div></div>
                                      </td>

                                      <td className="align-middle __addedOn-column">
                                        <div className="text-sm py-4">
                                          {company.created_on
                                            ? (() => {
                                                const createdDate = new Date(
                                                  company.created_on,
                                                );
                                                const daysAgo = Math.floor(
                                                  (new Date() - createdDate) /
                                                    (1000 * 60 * 60 * 24),
                                                );
                                                return `${daysAgo} day${daysAgo !== 1 ? "s" : ""} ago`;
                                              })()
                                            : "-"}
                                        </div>
                                      </td>

                                      <td className="align-middle __actions-column">
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Link
                                            to="/shop"
                                            style={{ textDecoration: "none" }}
                                          >
                                            <Assignment
                                              style={{
                                                color: "#C49733",
                                                cursor: "pointer",
                                                fontSize: 30,
                                              }}
                                            />
                                          </Link>
                                          <HighlightOff
                                            style={{
                                              color: "red",
                                              cursor: "pointer",
                                              fontSize: 30,
                                            }}
                                            onClick={(event) =>
                                              this.flagged_popup(event, company)
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {this.state.selected_company.length > 0 && (
                <ReviewsCards
                  {...this.state}
                  CompanyFileNew={CompanyFileNew}
                  handleHide={this.handleHide}
                  getAvatarUrl={this.getAvatarUrl}
                  getBackgroundUrl={this.getBackgroundUrl}
                  handleToggle={this.handleToggle}
                  changeRating={this.changeRating}
                  handleChange={this.handleChange}
                  editReviewSubmit={this.editReviewSubmit}
                  reviewSubmit={this.reviewSubmit}
                  roundAdditionalCriteria={this.roundAdditionalCriteria}
                  opportunity_data={this.props.opportunity_data}
                  handleChangeAdditionalRating={
                    this.handleChangeAdditionalRating
                  }
                  flagged_popup={this.flagged_popup}
                  openTableModal={this.openTableModal}
                  handleChangeAdditionalRatingComment={
                    this.handleChangeAdditionalRatingComment
                  }
                  updateStatus={this.state.updatedStatus}
                />
              )}
            </>
          ) : (
            <div className="__submission-page top-4">
              <div className={classnames("SocialLoadingIcon")}>
                <ThreeDotsIcon />
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
    );
  }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Page;
