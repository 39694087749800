/* (2019-06-11) - The card used in the SIR/application room in the submissions
 page. Displays the amalgamated score of all the ratings at the top.
*/
import React from "react";
import PropTypes from "prop-types";
import URL from "url-parse";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import default_background from "../../img/bg.png";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import EmailIcon from "@material-ui/icons/Email";
import FlagIcon from "@material-ui/icons/Flag";
import WebsiteIcon from "@material-ui/icons/Cloud";
import AttachIcon from "@material-ui/icons/AttachFile";
import AddIcon from "@material-ui/icons/Add";
import "./application_room_card_submissions.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import FolderIcon from "@material-ui/icons/Folder";
import Button from "@material-ui/core/Button";
import Moment from "moment";
// import { Modal } from "react-bootstrap";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { SocialIcon } from "react-social-icons";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../boilerplate/list.json";
import {
  Explore_Share_Popup,
  Report_Company_Flagging,
  Add_To_Community,
} from "./popups";
import Applicant_Info_Popup from "./popups/applicant_info_popup";

import _compact from "lodash/compact";
import _flatten from "lodash/flatten";
import _includes from "lodash/includes";
import _forEach from "lodash/forEach";
import _intersection from "lodash/intersection";
import _difference from "lodash/difference";
import BuyButton from "./BuyButton";

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  root: {
    marginLeft: theme.spacing.unit * 1.3,
    marginTop: "16px",
  },
  header: {
    overflow: "hidden",
  },
  chip: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  CardHeight: {
    overflow: "hidden",
    padding: "16px 5px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "0",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  type: {
    fontSize: "1em",
    color: "red",
    textAlign: "center",
    fontWeight: 0,
  },
  footer: {
    display: "flex",
  },
  title: {
    fontSize: "1rem",
    overflow: "hidden",
    height: 25,
  },
  grow: {
    flexGrow: 1,
  },
  iconHover: {
    "&:hover": {
      color: red[800],
    },
  },
});

function handleClick() {
  alert("You clicked the Chip.");
}

const options = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "Review",
    value: "review",
  },
  {
    label: "First Diligence",
    value: "firstdiligence",
  },
  {
    label: "Deep Diligence",
    value: "deepdilligence",
  },
  {
    label: "Offer",
    value: "offer",
  },
  {
    label: "Portco",
    value: "portco",
  },
];

class RecipeReviewCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      url: new URL(window.location.href, true),
      showSharePopup: false,
      showFlagPopup: false,
      showCommunityPopup: false,
      application_status: "",
      isOpen: false,
      expanded_top: true,
      popup_header: "Are you sure you would like to flag COMPANY INC?",
      popup_body:
        "By flagging this company, it will be sent to the admin for review where they will have the ability to delete the company from this directory.",
      show_info_popup: false,
      show_buy_popup: false,
    };
    this.handleOneChange = this.handleOneChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    console.log("this.props", this.props);
  }

  handleOneChange(e) {
    this.setState({ application_status: e.target.value });
  }

  openModal = () => {
    this.setState({ isOpen: true });
    console.log("get the data", this.props);
  };
  closeModal() {
    this.setState({ isOpen: false });
    this.props.updateStatusCard();
  }

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };
  handleSharePopup = (e) => {
    this.setState({
      showSharePopup: !this.state.showSharePopup,
    });
  };
  flagged_popup = (e, company_name) => {
    e.preventDefault();
    this.setState({
      showFlagPopup: true,
      popup_header: `Delete ${company_name}`,
      popup_body:
        "By deleting this company, it will be sent to the admin for review. Are you sure you would like to proceed?",
    });
  };

  add_to_community_popup = (e, company_name) => {
    e.preventDefault();
    this.setState({
      showCommunityPopup: true,
      popup_header: `Would you like to add ${company_name}, to your community?`,
      popup_body:
        "By adding this company to your community, they will be listed in your community directory and have access to resources that you choose to share with them.",
    });
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleHide = (e) => {
    console.log("abc");
    this.setState({
      showPopUpRate: false,
      showFlagPopup: false,
      showCommunityPopup: false,
    });
  };

  create_flagged = (e, company_id) => {
    e.preventDefault();
    axios
      .post("/create_flagged_company_in_opportunity", {
        flagged_company: company_id,
        opportunity: this.state.url.query.opportunity_id,
        flagged_by: this.props.user._id,
      })
      .then((response) => {
        axios
          .post(
            `/delete_flagged_company_from_opportunity?opportunity_id=${this.state.url.query.opportunity_id}&&company_id=${company_id}`,
          )
          .then((response) => {
            this.setState({
              showFlagPopup: false,
            });
          });
      });
  };

  changestatus = (e, company_id) => {
    e.preventDefault();

    const data = {
      user: this.props._id,
      applicant_company: this.props.applicant_company,
      pertains_to_opportunity: this.state.url.query.opportunity_id,
      application_status: this.state.application_status,
    };
    this.props.updateStatus(data);
    // this.setState({
    //   isOpen: false,
    // });
    // e.preventDefault();
  };

  add_company_community = (e, company_id) => {
    e.preventDefault();
    axios
      .post("/create_community_affiliation", {
        opp_company: this.props.opportunity_data.affiliated_company,
        opp_id: this.state.url.query.opportunity_id,
        opp_company_status: "Approved",
        applicant_company: company_id,
        applicant_company_status: "Approved",
      })
      .then((response) => {});
  };

  show_info_popup = () => {
    this.setState({
      show_info_popup: !this.state.show_info_popup,
    });
  };
  show_buy_popup = () => {
    this.setState({
      show_buy_popup: !this.state.show_buy_popup,
    });
  };

  render() {
    const { classes } = this.props;
    var allTags = _compact(
      _flatten([
        this.props.business_model,
        this.props.getStage(this.props.stage, stage_list),
        this.props.country,
        _compact(this.props.industry).map((val) =>
          val == "Artificial Intelligence" ? "A.I" : val,
        ),
      ]),
    );

    var filterTags = [];
    _forEach(
      this.props.filters,
      function (value, key, obj) {
        if (key === "stage") {
          _forEach(
            value,
            function (val) {
              filterTags.push(this.props.getStage(val, stage_list));
            }.bind(this),
          );
        } else if (key === "industry") {
          _forEach(
            value,
            function (val) {
              filterTags.push(val == "Artificial Intelligence" ? "A.I" : val);
            }.bind(this),
          );
        } else if (key === "country") {
          filterTags = filterTags.concat(value);
        }
      }.bind(this),
    );

    var showFilterTags = _intersection(filterTags, allTags);
    var showRemainderTags = _difference(
      allTags,
      _intersection(filterTags, allTags),
    );

    var getTagColorClass = function (tag) {
      if (this.props.business_model === tag) {
        return "blueHl";
      } else if (this.props.getStage(this.props.stage, stage_list) === tag) {
        return "redHl";
      } else if (this.props.country === tag) {
        return "";
      } else {
        return "greenHl";
      }
    }.bind(this);

    return (
      <>
        <Modal
          // dialogClassName="my-modal"
          // aria-labelledby="example-custom-modal-styling-title example-modal-sizes-title-sm"
          // size="sm"
          open={this.state.isOpen}
          onClose={this.closeModal}
          center
        >
          {/* <Modal.Header>
            <span
              style={{
                textAlign: "center",
                color: "#5e72e4",
                fontSize: "30px",
              }}
            > */}
          <h2 className="change-status-heading">
            Change Status of{" "}
            <span style={{ fontWeight: "bold", color: "#5e72e4" }}>
              {this.props.company_name}
            </span>
          </h2>
          {/* </span> */}
          {/* </Modal.Header> */}

          {/* <Modal.Body> */}
          <div className="change-status-select">
            {!this.props.statusChanged && (
              <select
                value={this.state.application_status}
                onChange={this.handleOneChange}
                style={{
                  // border: "2px solid #29BD00",
                  // borderRadius: "12px",
                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "white",
                  margin: "0px 2px 0px 0px ",
                  fontSize: 14,
                  width: "100%",
                }}
              >
                {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            )}
            {this.props.statusChanged && (
              <h3 class="text-center">Status Changed</h3>
            )}
          </div>
          {/* </Modal.Body> */}
          {/* <Modal.Footer> */}
          <div className="button-change-status">
            {!this.props.statusChanged && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.changestatus}
                size="small"
                className="button-status"
              >
                Submit
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={this.closeModal}
              size="small"
            >
              Close
            </Button>
          </div>
          {/* </Modal.Footer> */}
        </Modal>

        <Card
          className={`${classes.Card} ${classes.grow} ${classes.root} SubmissionProfileCard`}
        >
          <Report_Company_Flagging
            show={this.state.showFlagPopup}
            handleChange={this.handleChange}
            dismiss={this.handleHide}
            company_id={this.props.applicant_company}
            create_flagged={this.create_flagged}
            {...this.state}
          />
          <Add_To_Community
            show={this.state.showCommunityPopup}
            handleChange={this.handleChange}
            dismiss={this.handleHide}
            company_id={this.props.applicant_company}
            add_company_community={this.add_company_community}
            {...this.state}
          />
          <Explore_Share_Popup
            {...this.state}
            {...this.props}
            handleChange={this.handleChange}
            handleSharePopup={this.handleSharePopup}
          />
          <div className="UBaidSubCardHeader">
            <img
              className="UbaidSubCardAvtar"
              src={this.props.getAvatarUrl(
                this.props.company_files,
                "company avatar",
              )}
            />
            <div className="UbaidSubCardCompanyName">
              <a
                onClick={(event) =>
                  this.props.previewSelectedCompany(
                    event,
                    this.props.applicant_company,
                  )
                }
              >
                <div>{this.props.company_name}</div>
              </a>
              <div className="UBaidSubCardSubtitle">
                # {this.props.company_type}
              </div>
            </div>
            <div id="popup">
              <a href="/shop" target="_blank" rel="noopener noreferrer">
                <button
                  type="button"
                  class="btn btn-sm btn-outline-primary active"
                >
                  Report
                </button>
              </a>
            </div>
            <div>
              <div>
                {/* */}
                <div className="UbaidCardActions">
                  {/* <span
                    onClick={
                      this.props.overallRatingArr === undefined
                        ? this.handleSharePopup
                        : null
                    }
                  >
                    {this.props.overallRatingArr === undefined ? (
                      <i className="material-icons blue-text" style={{ fontSize: "30px", padding:"0 0 5px 0" }}>share</i>
                    ) : (
                      <span>
                        {this.props.overallRatingArr.overallRatingArr}{" "}
                      </span>
                    )}
                  </span> */}
                  <i
                    className="material-icons red-text"
                    style={{ fontSize: "30px", padding: "0 0 5px 0" }}
                    onClick={(event) =>
                      this.flagged_popup(event, this.props.company_name)
                    }
                  >
                    delete
                  </i>
                </div>
                {/* */}
                <div className="UbaidCardActions">
                  {/*
                  {this.props.companies_owned !== undefined &&
                    this.props.companies_owned !== null &&
                    this.props.companies_owned[1] !== undefined &&
                    this.props.companies_owned[1][0] !== undefined &&
                    this.props.companies_owned[1][0]._id ===
                      this.props.opportunity_data.affiliated_company && (
                      <>
                        {this.props.comm_aff !== undefined &&
                        this.props.comm_aff !== null &&
                        this.props.comm_aff.length !== 0 ? (
                          <i
                            className="material-icons grey-text"
                            style={{ fontWeight: "bold", color: "green" }}
                          >
                            bookmark
                          </i>
                        ) : (
                          <i
                            className="material-icons"
                            style={{ fontWeight: "bold" }}
                            onClick={(event) =>
                              this.add_to_community_popup(
                                event,
                                this.props.company_name
                              )
                            }
                          >
                            add
                          </i>
                        )}
                      </>
                    )}*/}

                  <a
                    className="material-icons black-text"
                    href={`mailto:${this.props.company_owner.email}?Subject=Regarding Your Application`}
                    target="_top"
                  >
                    mail
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              paddingLeft: "16px",
              paddingBottom: "16px",
            }}
          >
            {this.props.created_on
              ? (() => {
                  const createdDate = new Date(this.props.created_on);
                  const daysAgo = Math.floor(
                    (new Date() - createdDate) / (1000 * 60 * 60 * 24),
                  );
                  return `Added ${daysAgo} day${daysAgo !== 1 ? "s" : ""} ago`;
                })()
              : "-"}
          </div>
          <div
            style={{
              textAlign: "left",
              paddingLeft: "16px",
              paddingBottom: "16px",
              fontWeight: "bold",
            }}
          >
            {this.props.company_score} Avg. Review Score
            <br />
            {this.props.ai_score}% AI Score
          </div>
          {/* */}

          <div style={{ display: "flex" }}>
            {/* */}
            {/* <div>
              <span style={{ color: "#3f51b5", fontWeight: "bold" , padding: "5px"}}>
                Rating
              </span>
            </div>  */}
            {/*  */}

            {/* */}
            {/* <div style={{ display: "contents" }}>
              {!this.props.ratings && (
                <>
                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                      textAlign: "center",
                    }}
                    className="fa  fa-star-o"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star-o"
                  ></i>
                </>
              )}

              {this.props.ratings == 2 && (
                <>
                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star-o"
                  ></i>
                </>
              )}
              {this.props.ratings == 3 && (
                <>
                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star-o"
                  ></i>
                </>
              )}

              {this.props.ratings == 4 && (
                <>
                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star-o"
                  ></i>
                </>
              )}

              {this.props.ratings == 3 && (
                <>
                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{
                      color: "#3f51b5",
                      fontSize: "10px",
                      width: "10px",
                    }}
                    className="fa fa-star"
                  ></i>
                </>
              )}
            </div>  */}
          </div>

          {this.props.pitch_comp_video_link !== undefined &&
          this.props.pitch_comp_video_link !== null &&
          this.props.pitch_comp_video_link.length > 0 &&
          this.props.opportunity_data.enter_pitch_video ? (
            <>
              <div className="exploreCardMedia">
                <iframe
                  style={{
                    margin: 0,
                    position: "absolute",
                    top: "0",
                    height: "100%",
                    width: "100%",
                  }}
                  src={this.props.background_video_link}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
              </div>
            </>
          ) : (
            <>
              {this.props.background_video_link !== undefined &&
              this.props.background_video_link !== null &&
              this.props.background_video_link.length > 0 ? (
                <div className="exploreCardMedia">
                  <iframe
                    style={{
                      margin: 0,
                      position: "absolute",
                      top: "0",
                      height: "100%",
                      width: "100%",
                    }}
                    src={this.props.background_video_link}
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              ) : (
                <CardActionArea
                  href={"/profile?company_id=" + this.props.applicant_company}
                  target="_blank"
                >
                  <CardMedia
                    className="exploreCardMedia"
                    image={this.props.getAvatarUrl(
                      this.props.company_files,
                      "company background",
                      this.props.temp_background_url,
                    )}
                    title="background_picture"
                  />
                </CardActionArea>
              )}
            </>
          )}

          <CardContent className="UbaidSubCardActionArea">
            {/* */}
            <Grid container direction="row">
              <Grid
                container
                direction="column"
                xs={12}
                sm={12}
                alignItems="center"
              >
                <div
                  className={classnames(
                    "exploreCardTags",
                    this.state.expanded_top && "expanded",
                  )}
                >
                  <div className="allTags">
                    {showFilterTags.concat(showRemainderTags).map((val) => (
                      <Chip
                        variant="outlined"
                        className={classnames(
                          "exploreTag",
                          getTagColorClass(val),
                        )}
                        color={
                          _includes(showFilterTags, val) ? "primary" : "default"
                        }
                        size="small"
                        label={val}
                      />
                    ))}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginTop: "10px" }}>
              {this.props.company_website !== undefined &&
                this.props.company_website.length > 0 && (
                  <Grid
                    container
                    direction="column"
                    xs={4}
                    sm={4}
                    alignItems="center"
                  >
                    <Typography variant="caption" gutterBottom>
                      Website
                    </Typography>
                    {this.props.company_website.includes("http") ||
                    this.props.company_website.includes("https") ? (
                      <a target="_blank" href={this.props.company_website}>
                        <WebsiteIcon />
                      </a>
                    ) : (
                      <a
                        target="_blank"
                        href={`http://${this.props.company_website}`}
                      >
                        <WebsiteIcon />
                      </a>
                    )}
                  </Grid>
                )}
              {this.props.getFileUrl(
                this.props.company_files,
                "company pitchdeck",
              ) !== undefined &&
                this.props.getFileUrl(
                  this.props.company_files,
                  "company pitchdeck",
                ) !== 0 && (
                  <Grid
                    container
                    direction="column"
                    xs={4}
                    sm={4}
                    alignItems="center"
                  >
                    <Typography variant="caption" gutterBottom>
                      Pitch Deck
                    </Typography>
                    <a
                      href={this.props.getFileUrl(
                        this.props.company_files,
                        "company pitchdeck",
                      )}
                    >
                      <AttachIcon />
                    </a>
                  </Grid>
                )}

              {this.props.linkedIn !== undefined &&
                this.props.linkedIn.length > 0 && (
                  <Grid
                    container
                    direction="column"
                    xs={4}
                    sm={4}
                    alignItems="center"
                  >
                    <Typography variant="caption" gutterBottom>
                      LinkedIn
                    </Typography>
                    <SocialIcon
                      style={{ margin: "3px", width: "30px", height: "30px" }}
                      target="_blank"
                      url={this.props.linkedIn}
                    />
                  </Grid>
                )}
            </Grid>{" "}
            {/*  */}
            <div className="__footer">
              <Button
                variant="contained"
                className="__info-btn"
                onClick={this.show_info_popup}
                size="small"
              >
                Info
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={this.openModal}
                size="small"
              >
                Status
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={(event) =>
                  this.props.previewSelectedCompany(
                    event,
                    this.props.applicant_company,
                  )
                }
                size="small"
              >
                Review
              </Button>
            </div>
          </CardContent>
          <Applicant_Info_Popup
            show={this.state.show_info_popup}
            dismissModal={this.show_info_popup}
            {...this.props}
          />

          {/* */}
          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                {typeof this.props.opportunity_data.opportunity_form ===
                  "object" &&
                  Object.keys(this.props.opportunity_data.opportunity_form).map(
                    (opp_form_key, i) => (
                      <div style={{ width: "inherit" }}>
                        {this.props.opportunity_data.opportunity_form[
                          opp_form_key
                        ]["display"] === true && (
                          <div>
                            <Typography
                              style={{
                                textAlign: "center",
                                fontStyle: "italic",
                                fontWeight: "bold",
                              }}
                              paragraph
                            >
                              {
                                this.props.opportunity_data.opportunity_form[
                                  opp_form_key
                                ]["title"]
                              }
                            </Typography>
                            <Typography paragraph>
                              {this.props[opp_form_key]}
                            </Typography>
                          </div>
                        )}
                      </div>
                    ),
                  )}

                <div style={{ width: "inherit" }}>
                  <Typography
                    style={{
                      textAlign: "center",
                      fontStyle: "italic",
                      fontWeight: "bold",
                    }}
                    paragraph
                  >
                    Contact Info
                  </Typography>
                  <p>
                    <span style={{ fontStyle: "italic" }}>Name: </span>{" "}
                    {this.props.company_owner.first_name}{" "}
                    {this.props.company_owner.last_name}{" "}
                  </p>
                  <p>
                    <span style={{ fontStyle: "italic" }}>Email: </span>{" "}
                    {this.props.company_owner.email}{" "}
                  </p>
                  <p>
                    <span style={{ fontStyle: "italic" }}>#: </span>{" "}
                    {this.props.contact_phone}{" "}
                  </p>
                </div>
              </Grid>
            </CardContent>
            <Grid className={classes.footer}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                className="col-3"
              >
                <CardActions className={classes.actions}>
                  <IconButton
                    className={classnames(classes.expand, {
                      [classes.expandOpen]: this.state.expanded,
                    })}
                    onClick={this.handleExpandClick}
                    aria-expanded={this.state.expanded}
                    aria-label="Show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <div>
                  <p
                    style={{
                      fontSize: "12px",
                      margin: "unset",
                      fontStyle: "oblique",
                    }}
                  >
                    {this.props.sub_vertical}
                  </p>
                </div>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
                className="col-4"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) =>
                    this.props.previewSelectedCompany(
                      event,
                      this.props.applicant_company,
                    )
                  }
                  className={classes.button}
                  size="small"
                >
                  Review
                </Button>
              </Grid>
            </Grid>
          </Collapse>
          {/* */}
        </Card>
      </>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
