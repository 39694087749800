import React, { useState, useEffect } from "react";
import axios from "axios";

const FounderInvitePage = ({ companyInfo }) => {
  const [founderEmail, setFounderEmail] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Fetch current founder email using companyInfo._id
  useEffect(() => {
    if (companyInfo.prospective_aff_email !== undefined) {
      if (companyInfo.prospective_aff_email) {
        setFounderEmail(companyInfo.prospective_aff_email);
      } else {
        setFounderEmail("No founder email set");
      }
    }
  }, [companyInfo.prospective_aff_email]);

  // Handle inviting a new founder
  const handleInvite = () => {
    if (!inputEmail) {
      setErrorMessage("Email is required.");
      return;
    }
    if (!/\S+@\S+\.\S+/.test(inputEmail)) {
      setErrorMessage("Invalid email format.");
      return;
    }

    setErrorMessage(""); // Clear errors if everything is valid

    // Send the invite via POST to the /invite_founder endpoint
    axios
      .post("/invite_founder", {
        founderEmail: inputEmail,
        companyId: companyInfo.applicant_company,
      })
      .then((response) => {
        if (response.data.status === "success") {
          setSuccessMessage("Invitation sent successfully.");
          setInputEmail(""); // Clear input field after invite
        } else {
          setErrorMessage("Error sending invitation. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error inviting founder:", error);
        setErrorMessage("Error sending invitation. Please try again.");
      });
  };

  // Inline styles
  const styles = {
    container: {
      margin: "0 auto",
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRadius: "12px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
      fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
      color: "#333",
    },
    card: {
      backgroundColor: "white",
      border: "1px solid #e0e0e0",
      borderRadius: "10px",
      padding: "20px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
      marginBottom: "20px",
    },
    heading: {
      fontSize: "24px",
      marginBottom: "10px",
    },
    subheading: {
      fontSize: "18px",
      marginBottom: "8px",
      color: "#555",
    },
    textGrey: {
      color: "gray",
      fontSize: "14px",
    },
    input: {
      padding: "8px",
      border: "1px solid gray",
      borderRadius: "4px",
      width: "250px",
      fontSize: "14px",
    },
    button: {
      marginTop: "10px",
      padding: "8px 12px",
      backgroundColor: "#4a90e2",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "14px",
      transition: "background-color 0.3s ease",
    },
    error: {
      color: "red",
      marginTop: "10px",
    },
    success: {
      color: "green",
      marginTop: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h4 style={styles.heading}>Founder Invite</h4>
        <p style={styles.textGrey}>
          Invite a founder to the deal, they will be able to update the company
          details by editing the submission.
        </p>

        {/* Current Founder Email Section */}
        <div style={{ marginTop: "20px" }}>
          <h5 style={styles.subheading}>Current Founder Email</h5>
          <p style={{ fontSize: "14px", color: "black" }}>
            {founderEmail || "No founder email set"}
          </p>
        </div>

        {/* Invite Founder Section */}
        <div style={{ marginTop: "20px" }}>
          <h5 style={styles.subheading}>Invite Founder</h5>
          <input
            type="email"
            placeholder="Enter founder email"
            value={inputEmail}
            onChange={(e) => setInputEmail(e.target.value)}
            style={styles.input}
          />
          <button
            onClick={handleInvite}
            style={styles.button}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#357abd")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#4a90e2")}
          >
            Invite Founder
          </button>

          {/* Error or Success message */}
          {errorMessage && <p style={styles.error}>{errorMessage}</p>}
          {successMessage && <p style={styles.success}>{successMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default FounderInvitePage;
