import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Charts.css";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import ProgressTrack from "./ProgressTrack/ProgressTrack";
import URL from "url-parse";

import bootstrap from "../../../../../pitch_box/assets/bootstrap.jpg";
import angular from "../../../../../pitch_box/assets/angular.jpg";
import sketch from "../../../../../pitch_box/assets/sketch.jpg";
import react from "../../../../../pitch_box/assets/react.jpg";

const Charts = (props) => {
  const [dealPipelineData, setDealPipelineData] = useState({
    new: 0,
    reviewing: 0,
    dilligence: 0,
    deepDilligence: 0,
    offer: 0,
    portco: 0,
    total: 0,
  });

  const [leads, setLeads] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const [reviews, setReviews] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    var url = new URL(window.location.href, true);
    var opportunityId = url.query.opportunity_id;

    axios
      .get(
        `fetch_submitted_companies_for_opportunity?opportunity_id=${opportunityId}`,
      )
      .then((res) => {
        let newCount = 0;
        let reviewingCount = 0;
        let dilligenceCount = 0;
        let deepDilligenceCount = 0;
        let portcoCount = 0;
        let offer = 0;
        let companies = res.data.full_company_list_with_flags;
        console.log(companies);
        companies.map((company) => {
          const createdOn = new Date(company.created_on);
          setLeads((prevLeads) => {
            const updatedLeads = [...prevLeads];
            updatedLeads[createdOn.getMonth()] += 1;
            return updatedLeads;
          });
          const companyReviews = company.reviews.length;
          setReviews((prevReviews) => {
            const updatedReviews = [...prevReviews];
            updatedReviews[createdOn.getMonth()] += companyReviews;
            return updatedReviews;
          });

          if (company.application_status === "new") {
            newCount++;
          }
          if (company.application_status === "review") {
            reviewingCount++;
          }
          if (company.application_status === "firstdiligence") {
            dilligenceCount++;
          }
          if (company.application_status === "deepdiligence") {
            deepDilligenceCount++;
          }
          if (company.application_status === "offer") {
            offer++;
          }
          if (company.application_status === "portco") {
            portcoCount++;
          }
        });
        setDealPipelineData({
          new: newCount,
          reviewing: reviewingCount,
          dilligence: dilligenceCount,
          deepDilligence: deepDilligenceCount,
          offer: offer,
          portco: portcoCount,
          total: companies.length,
        });
      });
  }, []);
  return (
    <div className="AragonCharts AragonFlex">
      <div className="AragonLineChartMain">
        <div className="AragonLineChart">
          <div className="AragonFlex AragonLineChartOverView">
            <div>
              <h6>OVERVIEW</h6>
              <h3>Leads Per Month</h3>
            </div>
          </div>
          <div style={{ height: "360px" }} className="lineabc">
            <LineChart data={leads} />
          </div>
        </div>
      </div>
      <div className="AragonBarChartMain">
        <div className="AragonBarChart">
          <div>
            <h6>TEAM ACTIVITY</h6>
            <h3>Reviews per month</h3>
          </div>
          <div style={{ height: "360px" }}>
            <BarChart data={reviews} />
          </div>
        </div>
      </div>
      <div className="AragonBarChartHorizontal">
        <h6>PIPELINE</h6>
        <h3>Stages of review</h3>
        <ProgressTrack
          avtar={bootstrap}
          title={` ${dealPipelineData.new} | Stage 1 - New Leads `}
          color="#FFFFFF"
          width={(dealPipelineData.new / dealPipelineData.total) * 100 + "%"}
        />
        <ProgressTrack
          avtar={angular}
          title={`  ${dealPipelineData.reviewing} | Stage 2 -  Reviewing`}
          color="#2DCE89"
          width={
            (dealPipelineData.reviewing / dealPipelineData.total) * 100 + "%"
          }
        />
        <ProgressTrack
          avtar={sketch}
          title={` ${dealPipelineData.dilligence} | Stage 3 - 1st Dilligence `}
          color="#F5365C"
          width={
            (dealPipelineData.dilligence / dealPipelineData.total) * 100 + "%"
          }
        />
        <ProgressTrack
          avtar={react}
          title={` ${dealPipelineData.deepDilligence} | Stage 4 -  Deep Dilligence `}
          color="#11CDEF"
          width={
            (dealPipelineData.deepDilligence / dealPipelineData.total) * 100 +
            "%"
          }
        />
        <ProgressTrack
          avtar={bootstrap}
          title={` ${dealPipelineData.offer} | Stage 5 -  Offer Signed`}
          color="#FB6340"
          width={(dealPipelineData.offer / dealPipelineData.total) * 100 + "%"}
        />
        <ProgressTrack
          avtar={bootstrap}
          title={` ${dealPipelineData.portco} | Stage 6 -  Portfolio Companies`}
          color="#ef11cd"
          width={
            (dealPipelineData.portco / dealPipelineData.portco) * 100 + "%"
          }
        />
      </div>
    </div>
  );
};

export default Charts;
